import { request } from 'data/requests/request';

const tireOptionsRequest = () =>
    request({
        type: 'tireOptionsResponse',
        url: 'carSearch/tireSearch/tireOptions',
        notApi: true,
        force: true,
    });

export default tireOptionsRequest;
