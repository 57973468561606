import * as React from 'react';
import { useEffect } from 'react';
import { IAdsAddress } from '../../interfaces/checkout/address/IAdsAddress';
import { useMutation } from 'redux-query-react';
import { genericRequest } from '../../data/requests/genericRequest';
import ControlAutocomplete, { IAutocompleteOption } from 'automaailm-ui/lib/Components/ControlAutocomplete/index';
import ControlSelect from 'automaailm-ui/lib/Components/ControlSelect/index';
import { useState } from 'react';
import { IAdsAppartment, IAdsResponseAddress, IAdsResponse } from '../../interfaces/checkout/address/IAdsResponse';
import { ActionPromiseValue } from 'redux-query';
import FormRow from 'automaailm-ui/lib/Components/FormRow/index';
import { useTranslation } from 'react-i18next';

const AdsSearch = () => {
    const { t } = useTranslation();
    const [{}, addressRequest] = useMutation<IAdsResponse>(() =>
        genericRequest({
            type: 'address',
            url: `${window.inAddressConfiguration.url}`.replace('{value}', value),
        }),
    );
    const [address, setAddress] = useState<IAdsResponseAddress | undefined>();
    const [value, setValue] = useState('');
    const [aptValue, setAptValue] = useState('');
    const initialOptionList: IAutocompleteOption[] = [{ label: '', value: '' }];
    const [optionList, setOptionList] = useState<IAutocompleteOption[]>(initialOptionList);
    const [aptOptionList, setAptOptionList] = useState<IAutocompleteOption[] | undefined>();

    useEffect(() => {
        if (address) {
            const aptString = aptValue ? `-${aptValue}` : '';
            const detail: IAdsAddress = {
                region_id:
                    window.inAddressConfiguration.countyIdMap?.find((item) => item.adsCode === address.ehakmk)?.id || 0,
                street: [`${address.aadresstekst}${aptString}`],
                postcode: address.sihtnumber,
                city: address.omavalitsus,
            };
            window.dispatchEvent(new CustomEvent('ads-address-select', { detail }));
        }
    }, [address, aptValue]);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (value.length > 2) {
                addressRequest().then((response: any) => {
                    if (response.body && response.body.addresses) {
                        setOptionList(
                            response.body.addresses.map((addr: IAdsResponseAddress) => {
                                return {
                                    label: addr.ipikkaadress,
                                    value: addr.ipikkaadress,
                                    data: addr,
                                };
                            }),
                        );
                    }
                });
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [value]);
    return (
        <React.Fragment>
            <FormRow label={t('checkout.Address search')}>
                <ControlAutocomplete
                    value={value}
                    data={optionList}
                    onChange={(e) => setValue(e)}
                    onSelect={(e) => {
                        setValue(e.label);
                        if ((e.data as IAdsResponseAddress).appartments?.length) {
                            setAptOptionList(
                                e.data.appartments.map((apt: IAdsAppartment) => {
                                    return (
                                        <option key={apt.tahis} value={apt.tahis}>
                                            {apt.tahis}
                                        </option>
                                    );
                                }),
                            );
                        } else {
                            setAptOptionList(undefined);
                        }
                        setAddress(e.data);
                    }}
                    minChars={3}
                />
            </FormRow>
            {aptOptionList && (
                <React.Fragment>
                    <FormRow label={t('checkout.Select apartment')}>
                        <ControlSelect value={aptValue} onChange={(e) => setAptValue(e.target.value)} size={'small'}>
                            {aptOptionList}
                        </ControlSelect>
                    </FormRow>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default AdsSearch;
