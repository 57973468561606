import React from 'react';
import { Category } from 'components/CategoryList/CategoryList';
import Subcategory from 'components/CategoryList/Subcategory';

interface IProps {
    categories?: Array<Category>;
    carId: string;
}

const ListItemSubcategory = (props: IProps) => {
    const { categories, carId } = props;

    return (
        <ul>
            {categories &&
                categories.map((category, index) => {
                    const id = `cat-${index}${category.assemblyGroupNodeId}`;
                    if (category.hasChilds) {
                        return (
                            <li key={id}>
                                <input type="checkbox" id={id} />
                                <div className="item-row">
                                    <label htmlFor={id}>{category.assemblyGroupName}</label>
                                </div>
                                <ListItemSubcategory categories={category.children} carId={carId} />
                            </li>
                        );
                    } else {
                        return (
                            <Subcategory
                                id={category.assemblyGroupNodeId}
                                name={category.assemblyGroupName}
                                key={id}
                                href={category.href}
                            />
                        );
                    }
                })}
        </ul>
    );
};

export default ListItemSubcategory;
