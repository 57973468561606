import * as React from 'react';
import { includes } from 'lodash';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CategoryTree from 'automaailm-ui/lib/Components/CategoryTree';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { IStoreState } from '../../../helpers/rootReducer';
import { useEffect } from 'react';
import { setActiveItems } from '../../../helpers/sidebar/setActiveItems';

export interface IFilterSidebarProps {
    /* DOM to parse */
    dom: string;
    /** Unsafe config */
    config?: any;
}

const FilterSidebar: React.FunctionComponent<IFilterSidebarProps> = (props) => {
    const hasActiveSidebar = props.config.hasActiveSidebar ?? false;
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    // Used for CMS pages sidebar menu which is inserted as a block from Magento Admin. The structure is fixed and has 1 level
    useEffect(() => {
        if (hasActiveSidebar) {
            setActiveItems();
        }
    }, []);

    return (
        <CategoryTree
            dom={props.dom}
            openMobileFilter={(e) => {
                e.preventDefault();
                dispatch(closeOverlay({ name: 'all' }));
                dispatch(openOverlay({ name: 'mobilefilter' }));
            }}
            closeMobileFilter={(e) => {
                e.preventDefault();
                dispatch(closeOverlay({ name: 'all' }));
            }}
            mobileFilterOpen={includes(openOverlays, 'mobilefilter')}
            link={props.config && props.config.disableRouter ? undefined : NavLink}
            query={location.search}
            location={location}
            history={history}
            keepOriginalHref={true}
        />
    );
};

FilterSidebar.displayName = 'FilterSidebar';

export default FilterSidebar;
