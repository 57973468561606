import React from 'react';
import { Tire } from 'components/SearchBlock/TireSearch/LicencePlate/Overlay/TireOverlay';

interface IProps {
    tire: Tire;
}

const TireSize = (props: IProps) => {
    const { tire } = props;

    return (
        <td>
            {tire.href && <a href={tire.href}>{tire.name}</a>}
            {!tire.href && tire.name}
        </td>
    );
};

export default TireSize;
