import * as React from 'react';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { getItem } from '../../helpers/storage';

export interface ICartButtonProps {
    /* DOM to parse */
    dom: string;
    config: {
        href: string;
        src: string;
        label: string;
    };
}

const mapStateToProps = (state) => {
    return {
        grandTotal: state.entities.primitiveCart?.grandTotal,
        itemsCount: state.entities.primitiveCart?.itemsCount,
    };
};

const CartButton: React.FunctionComponent<ICartButtonProps> = (props) => {
    const [{}, cartRequest] = useRequest(request({ type: 'primitiveCart', url: 'fast/cart/info', notApi: true }));
    let { grandTotal, itemsCount } = useSelector(mapStateToProps);
    const [total, setTotal] = useState(getItem('grandTotal'));
    const [count, setCount] = useState(getItem('itemsCount'));

    useEffect(() => {
        setTotal(grandTotal);
        setCount(itemsCount);
    }, [grandTotal, itemsCount]);

    const effect = useCallback(async () => {
        const response = await cartRequest();
        setTotal(response.body?.grandTotal);
        setCount(response.body?.itemsCount);
    }, [cartRequest]);

    useEffect(() => {
        window.addEventListener('cart-altered', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    }, [cartRequest]);
    return (
        <a href={props.config.href || ''}>
            <span className="icon">
                <img src={props.config.src || ''} alt={props.config.label || ''} />
            </span>
            <span className="label">{props.config.label || ''}</span>
            <b className="amount">{total}</b>
            <b className="products">{count}</b>
        </a>
    );
};

CartButton.displayName = 'CartButton';

export default CartButton;
