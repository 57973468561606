import * as React from 'react';
import { Assets, Product } from 'components/Catalog/Interfaces/List/IListResponse';
import Products from 'components/Catalog/Product/ListView/Products';

interface IProps {
    config: {
        products?: Product[];
        assets?: Assets;
    };
}

export const ProductsGrid = (props: IProps) => {
    if (!props.config.products || !props.config.products.length) {
        return <React.Fragment />;
    }
    return <Products products={props.config.products} assets={props.config.assets} ignoreListView={true} />;
};
