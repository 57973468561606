import * as React from 'react';
import { getIconUrl } from '../../helpers/image/iconUrl';

declare global {
    interface Window {
        assetUrl?: string;
    }
}

interface IProps {
    iconName: string;
    alt?: string;
}

const Img = (props: IProps) => {
    const { iconName, alt } = props;
    return <img src={getIconUrl(iconName)} alt={alt} />;
};

export default Img;
