import * as React from 'react';
import classNames from 'classnames';
import { getScrollParent, usePrevious } from 'automaailm-ui/lib/Utilities/Generic';
import { scrollIntoView } from 'automaailm-ui/lib/Utilities/ScrollTo';

export interface ISearchResultProps {
    /** Product name */
    name: string;
    /** Product price */
    price?: string;
    /** href to product */
    href: string;
    /* Product image */
    imageUrl?: string;
    /** Highlight as active */
    active?: boolean;
}

const ResultProduct: React.FunctionComponent<ISearchResultProps> = (props) => {
    const prevActive = usePrevious(props.active);
    const domElem = React.useRef(null);

    // If active then try to scroll into view

    React.useEffect(() => {
        if (props.active && !prevActive) {
            const d = (domElem.current as unknown) as HTMLElement;
            if (d) {
                const domElemParent = getScrollParent(d);
                scrollIntoView(d, domElemParent, {});
            }
        }
    }, [props.active, prevActive]);

    // Main output
    return (
        <li className="search__results__result" ref={domElem}>
            <a href={props.href} className={classNames({ active: props.active })}>
                <div className="image" style={{ backgroundImage: 'url(' + props.imageUrl + ')' }} />
                <div className="text">
                    <div className="name">{props.name}</div>
                    <div className="price">{props.price}</div>
                </div>
            </a>
        </li>
    );
};

export default ResultProduct;
