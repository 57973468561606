import React, { useEffect, useState } from 'react';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import modelRequest from 'data/carSearch/ModelRequest';
import Select from 'components/SearchBlock/CarSearch/SelectFields/Types/Select';
import { IModel } from 'components/SearchBlock/CarSearch/CarSelectSearch';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';

interface IProps {
    selectedParent?: IModel;
    manufacturerId: string;
    placeholder: string;
    selectedVehicle?: IVehicle;
    setSelectedVehicle: (vehicle?: IVehicle) => void;
}

const mapStateToProps = (state) => {
    return {
        models: state.entities.modelResponse as IModel[],
    };
};

const ModelSelect = (props: IProps) => {
    const { manufacturerId, selectedVehicle, setSelectedVehicle, placeholder } = props;
    const [model, setSelectedModel] = useState<IModel>();

    useEffect(() => {
        if (selectedVehicle && selectedVehicle.model) {
            setSelectedModel(selectedVehicle.model);
        }
    }, [selectedVehicle]);

    useEffect(() => {
        if (!model) {
            setSelectedVehicle({
                ...selectedVehicle,
                model: undefined,
                type: undefined,
            });
        } else {
            setSelectedVehicle({ ...selectedVehicle, model });
        }
    }, [model]);

    useRequest(modelRequest(manufacturerId));
    const { models } = useSelector(mapStateToProps);

    return (
        <Select placeholder={placeholder} models={models} setSelectedModel={setSelectedModel} selectedModel={model} />
    );
};

export default ModelSelect;
