import * as React from 'react';
import { Categories } from 'components/Catalog/Interfaces/List/IListResponse';

interface IProps {
    categories: Categories;
}

const CategoryFilters = (props: IProps) => {
    const { categories } = props;

    const categorySubComponents = (children) => {
        return (
            <React.Fragment>
                {!!children?.length && (
                    <ul>
                        {children.map((childCategory, filterKey) => {
                            return (
                                <li key={filterKey}>
                                    <a href={`${childCategory.url}`} className={childCategory.active ? 'active' : ''}>
                                        {childCategory.name}
                                    </a>
                                    {categorySubComponents(childCategory.children)}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {categories.items &&
                categories.items.map((category) => {
                    return (
                        <div className={'category-tree__group'} key={`group-${category.id}`}>
                            <input
                                defaultChecked={category.active}
                                type="checkbox"
                                id={`sidebar-group-${category.id}`}
                            />
                            <label htmlFor={`sidebar-group-${category.id}`}>{category.name}</label>
                            {categorySubComponents(category.children)}
                        </div>
                    );
                })}
        </React.Fragment>
    );
};

export default CategoryFilters;
