import { request } from 'data/requests/request';

const vehicleDeleteRequest = (vehicleId) =>
    request({
        type: 'vehicleDelete',
        url: 'vehicle/vehicle/delete',
        method: 'POST',
        data: { vehicleId },
        notApi: true,
    });

export default vehicleDeleteRequest;
