import React, { useEffect, useState } from 'react';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import Select from 'components/SearchBlock/CarSearch/SelectFields/Types/Select';
import { IModel } from '../CarSelectSearch';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import typeRequest from 'data/carSearch/TypeRequest';

interface IProps {
    placeholder: string;
    manufacturerId: string;
    modelId: string;
    selectedVehicle?: IVehicle;
    setSelectedVehicle: (vehicle?: IVehicle) => void;
}

const mapStateToProps = (state) => {
    return {
        types: state.entities.typeResponse as IModel[],
    };
};

const TypeSelect = (props: IProps) => {
    const { placeholder, manufacturerId, modelId, selectedVehicle, setSelectedVehicle } = props;
    const [type, setSelectedType] = useState<IModel>();

    useEffect(() => {
        if (selectedVehicle && selectedVehicle.type) {
            setSelectedType(selectedVehicle.type);
        }
    }, [selectedVehicle]);

    useEffect(() => {
        if (selectedVehicle) {
            setSelectedVehicle({ ...selectedVehicle, type });
        }
    }, [type]);

    useRequest(typeRequest(manufacturerId, modelId));
    const { types } = useSelector(mapStateToProps);

    return <Select placeholder={placeholder} models={types} setSelectedModel={setSelectedType} selectedModel={type} />;
};

export default TypeSelect;
