import * as React from 'react';
import { IAttribute } from 'components/Catalog/Interfaces/IAttribute';
import { ISwatch } from 'components/Catalog/Interfaces/ISwatch';
import { ISelectedAttributes, ISelectedSwatch } from 'components/Catalog/Product/Configurator';

interface IProps {
    attribute: IAttribute;
    swatches: ISwatch[][];
    selectedAttributes: ISelectedAttributes[];
    selectAttributes: (attributes: ISelectedAttributes[]) => void;
    defaultSelectedMainSwatch: ISelectedSwatch;
    setDefaultSelectedMainSwatch: (attributes: ISelectedSwatch) => void;
}
const Attribute = (props: IProps) => {
    const {
        attribute,
        swatches,
        selectedAttributes,
        selectAttributes,
        defaultSelectedMainSwatch,
        setDefaultSelectedMainSwatch,
    } = props;
    if (!attribute) {
        return <React.Fragment />;
    }

    const getHasMappedSwatch = (attributeId, optionId): ISwatch | undefined => {
        let result = undefined;
        Object.keys(swatches).forEach((foundAttributeId) => {
            if (foundAttributeId === attributeId) {
                result = swatches[foundAttributeId][optionId];
            }
        });
        return result;
    };

    const isSelectedAttribute = (attributeId, optionId) => {
        return !!selectedAttributes?.find((selectedAttribute) => {
            return selectedAttribute.attributeId === attributeId && selectedAttribute.optionId === optionId;
        });
    };

    const selectAttribute = (attributeId, optionId, products) => {
        if (attributeId === defaultSelectedMainSwatch.attributeId) {
            setDefaultSelectedMainSwatch({
                attributeId: attributeId,
                optionId: optionId,
                products: products,
            });
        }
        const newlySelectedAttributes = selectedAttributes.map((selectedAttribute) => {
            if (selectedAttribute.attributeId === attributeId) {
                selectedAttribute.optionId = optionId;
            }
            return selectedAttribute;
        });
        selectAttributes(newlySelectedAttributes);
    };

    return (
        <React.Fragment>
            <div className="product-configurator__title">{attribute.label}</div>
            <ul className="product-configurator__group">
                {attribute.options &&
                    attribute.options.map((option, key) => {
                        // If has any colors set
                        const color = getHasMappedSwatch(attribute.id, option.id);
                        // If is currently selected
                        const isSelected = isSelectedAttribute(attribute.id, option.id);
                        return (
                            <li key={key}>
                                <label>
                                    <input
                                        type="radio"
                                        value={option.id}
                                        checked={isSelected}
                                        onChange={() => selectAttribute(attribute.id, option.id, option.products)}
                                    />
                                    {color && color.value && (
                                        <span className="swatch" style={{ background: color.value }} />
                                    )}
                                    {!color && <span className="label">{option.label}</span>}
                                </label>
                            </li>
                        );
                    })}
            </ul>
        </React.Fragment>
    );
};

export default Attribute;
