import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { ICartProduct, ILabelledString, IRequiredProduct } from 'components/Cart/Interfaces/ICartProduct';
import { useMutation } from 'redux-query-react';
import { postRequest } from 'data/requests/postRequest';
import { extractErrors } from '../../../helpers/request/extractErrors';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';
import RelatedProduct from 'components/Cart/Product/RelatedProduct';
import RequiredProduct from 'components/Cart/Product/RequiredProduct';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import { useSelector } from 'react-redux';
import { empty } from '../../../helpers/empty';
import tecDocProductAttributesRequest from 'data/requests/product/tecDocProductAttributesRequest';

export interface ICartProductProps {
    quoteId: string;
    product: ICartProduct;
    setError: (message: string) => void;
}

const mapStateToProps = (state) => {
    return state.selectedVehicleReducer.selectedVehicle as IVehicle;
};

const Product = (props: ICartProductProps) => {
    const { quoteId, setError } = props;

    const [product, setProduct] = useState<ICartProduct>(props.product);
    const [selectedVehicle] = useState<IVehicle | undefined>(useSelector(mapStateToProps));
    const [{}, tecDocAttributesRequest] = useMutation((tecDocArticleId: string, typeId: string) =>
        tecDocProductAttributesRequest(tecDocArticleId, typeId),
    );

    useEffect(() => {
        setProduct(props.product);
    }, [props.product]);

    const [tecDocQueryDone, setTecDocQueryDone] = useState<boolean>(false);

    const mergeOptions = (newOptions, old) => {
        return [...old, ...newOptions];
    };

    useEffect(() => {
        if (
            !tecDocQueryDone &&
            !empty(selectedVehicle) &&
            !empty(product.tecDocArticleId) &&
            selectedVehicle?.type?.id &&
            (!product.options || product.options.length < 4)
        ) {
            tecDocAttributesRequest(product.tecDocArticleId, selectedVehicle?.type?.id).then((response) => {
                const attributes = response.body;
                if (attributes instanceof Array) {
                    const additionalOptions: ILabelledString[] = attributes
                        .splice(0, 4 - (!product.options ? 0 : product.options.length))
                        .map((response) => {
                            return { label: response.attributeName, value: response.attributeValue };
                        });

                    const options = product?.options
                        ? mergeOptions(additionalOptions, product?.options)
                        : additionalOptions;
                    setProduct({
                        ...product,
                        options,
                    });
                }
                setTecDocQueryDone(true);
            });
        }
    }, [selectedVehicle, product, tecDocQueryDone]);

    const [{}, cartRequest] = useMutation((data, id, method) =>
        postRequest({
            method: method,
            type: 'response',
            url: isLoggedIn() ? `carts/mine/items/${id}` : `guest-carts/${quoteId}/items/${id}`,
            data,
        }),
    );
    const [loading, setIsLoading] = useState(false);
    const alterProduct = useCallback(
        async (id: any, sku: string, qty: number) => {
            if (loading) {
                return;
            }
            setIsLoading(true);
            const data: any = {
                cartItem: {
                    sku,
                    qty,
                    quote_id: quoteId,
                },
            };
            const response = await cartRequest(data, id, 'PUT' as any);
            setIsLoading(false);
            if (response.status === 200) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
            } else {
                const errors = extractErrors(response);
                if (errors && errors.error) {
                    setError(errors.error);
                }
            }
        },
        [cartRequest],
    );

    const removeProduct = useCallback(
        async (id: any) => {
            if (loading) {
                return;
            }
            setIsLoading(true);
            const response = await cartRequest({} as any, id, 'DELETE' as any);
            setIsLoading(false);
            if (response.status === 200) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
            } else {
                const errors = extractErrors(response);
                if (errors && errors.error) {
                    setError(errors.error);
                }
            }
        },
        [cartRequest],
    );

    return (
        <React.Fragment>
            <tr>
                <td className={'cart-table-image'}>
                    <a href={product.url}>
                        <span className={'image'} style={{ backgroundImage: 'url(' + product.image + ')' }} />
                    </a>
                </td>
                <td>
                    <p className={'cart-table-title'}>
                        <a href={product.url}>{product.name}</a>
                    </p>
                    {product.callout && <p className="cart-callout">{product.callout}</p>}
                    {product.options && (
                        <ul className="cart-table-specs">
                            {product.options.map((option) => (
                                <li key={option.label}>
                                    <span className="label">{option.label}</span>{' '}
                                    <span className="value">{option.value}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                </td>
                <td>
                    <ul className={'cart-table-price'}>
                        <li>
                            <span className="label">{product.price.label}:</span>
                            <span className="value sum">{product.price.value}</span>
                        </li>
                        <li>
                            <span className={'label'}>{product.qty.label}:</span>
                            <span className={loading ? 'value loading' : 'value'}>
                                {!product.related?.length && (
                                    <a
                                        href={'#'}
                                        className={'value-button'}
                                        onClick={() =>
                                            alterProduct(product.id, product.sku, parseInt(product.qty.value) - 1)
                                        }
                                    >
                                        -
                                    </a>
                                )}
                                <span className={'number'}>{product.qty.value}</span>
                                {!product.related?.length && (
                                    <a
                                        href={'#'}
                                        className={'value-button'}
                                        onClick={() =>
                                            alterProduct(product.id, product.sku, parseInt(product.qty.value) + 1)
                                        }
                                    >
                                        +
                                    </a>
                                )}
                            </span>
                        </li>
                        <li className={'total'}>
                            <span className="label">{product.total.label}:</span>
                            <span className="value sum">{product.total.value}</span>
                        </li>
                    </ul>
                    {product.removeUrl && (
                        <p className={'cart-table-remove'}>
                            <a href={'#'} onClick={() => removeProduct(product.id)}>
                                {product.removeUrl.label}
                            </a>
                        </p>
                    )}
                </td>
            </tr>
            {product.related &&
                product.related.map((relatedProduct) => (
                    <RelatedProduct
                        key={relatedProduct.id}
                        relatedProduct={relatedProduct}
                        mainProduct={product}
                        loading={loading}
                        setIsLoading={setIsLoading}
                        setError={setError}
                    />
                ))}
            {product.requiredItems &&
                product.requiredItems.map((requiredProduct: IRequiredProduct) => (
                    <RequiredProduct key={requiredProduct.id} mainProduct={product} requiredProduct={requiredProduct} />
                ))}
        </React.Fragment>
    );
};

export default Product;
