import { request } from 'data/requests/request';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';

const vehicleAddRequest = (vehicle?: IVehicle) =>
    request({
        type: 'vehicleAdd',
        url: 'vehicle/vehicle/add',
        method: 'POST',
        data: { vehicle },
        notApi: true,
    });

export default vehicleAddRequest;
