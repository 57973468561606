import * as React from 'react';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import { useMutation, useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { PaymentMethodEnum } from '../../../../components/checkout/Payment/Methods/MethodHandler';
import { useSelector } from 'react-redux';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import Toaster from 'automaailm-ui/lib/Components/Toaster/index';
import { useEffect, useState } from 'react';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
}

const mapStateToProps = (state) => {
    return {
        bankLinks: state.entities?.[PaymentMethodEnum.esto_pay],
    };
};

interface Banklink {
    name: string;
    logo: string;
}

const EstoPay = (props: IProps) => {
    const [selectedBankCode, setSelectedBankCode] = useState('');

    const [{}, postData] = useMutation((banklinkCode: string) =>
        request({ type: 'esto', url: `esto/redirect/getPurchaseUrl`, data: { bank_id: banklinkCode }, notApi: true }),
    );
    const requestPayload = {
        type: PaymentMethodEnum.esto_pay,
        url: 'esto/bank/getBanks',
        notApi: true,
    };
    const [{ isFinished }] = useRequest(request(requestPayload));
    const { bankLinks } = useSelector(mapStateToProps);
    const methods: any[] = [];

    if (isFinished && bankLinks.length) {
        const simpleElement = (bankLink: Banklink) => (
            <SimplePayment
                method={PaymentMethodEnum.esto_pay}
                key={bankLink.name}
                onClick={() => {
                    setSelectedBankCode(bankLink.name);
                    props.paymentMethodProps.onClick({
                        redirect: undefined,
                        paymentMethodExtraData: {
                            additionalData: { payment_method_key: bankLink.name },
                        },
                    });
                }}
                logoUrl={bankLink.logo}
                label=""
                paymentMethodProps={props.paymentMethodProps}
            />
        );

        bankLinks?.forEach((bankLink) => methods.push(simpleElement(bankLink)));
    }

    const effect = (event) => {
        if (event.detail?.setRedirectUrl && event.detail.methodCode === PaymentMethodEnum.esto_pay) {
            postData(selectedBankCode).then((response) => {
                if (response.body.errorMessage) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.errorMessage,
                    });
                }
                if (response.body.url) {
                    event.detail.setRedirectUrl({ to: response.body.url, absolute: true });
                }
            });
        }
    };

    useEffect(() => {
        window.addEventListener('payment-response-receive', effect);
        return function cleanup() {
            window.removeEventListener('payment-response-receive', effect);
        };
    });

    return <React.Fragment>{isFinished && methods}</React.Fragment>;
};

export default EstoPay;
