import * as React from 'react';
import Buttons from 'automaailm-ui/lib/Components/Buttons/index';
import Button from 'automaailm-ui/lib/Components/Button/index';
import { useTranslation } from 'react-i18next';

export interface IProps {
    proceedAction: any | null;
    disabled?: boolean;
}

const ProceedButton = (props: IProps) => {
    const { proceedAction, disabled } = props;
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Buttons layout="vertical-wide">
                <Button
                    title={t('checkout.Continue')}
                    disabled={disabled}
                    onClick={() => {
                        proceedAction();
                    }}
                />
            </Buttons>
        </React.Fragment>
    );
};

export default ProceedButton;
