import * as React from 'react';
import { Assets, Product } from 'components/Catalog/Interfaces/List/IListResponse';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { parse } from 'query-string';
import ProductActions from '../ProductActions';
import Img from 'components/Image/Img';
import ListProductTecDocAttributes from 'components/Catalog/Product/ListProductTecDocAttributes';
import RenderHTML from 'automaailm-ui/lib/Components/RenderHTML';

interface IProps {
    products: Product[];
    ignoreListView?: boolean;
    inCartBlock?: string;
    assets?: Assets;
}

const Products = (props: IProps) => {
    const { products, ignoreListView } = props;

    let useListView = false;
    if (!ignoreListView) {
        const location = useLocation();
        const isCarRelatedListView = location.pathname.startsWith('/tecDocProductList');
        const queryString = parse(location.search);
        useListView = queryString.mode ? queryString.mode === 'list' : isCarRelatedListView;
    }

    return (
        <React.Fragment>
            <div className={classNames('layout-products__list', { list: useListView })}>
                {products &&
                    products.map((product, index) => (
                        <div className="layout-products__container" key={index}>
                            <div className="box-product">
                                <a href={product.url} className="box-product__link">
                                    {product.name}
                                </a>
                                <div className="box-product__image">
                                    <span style={{ backgroundImage: 'url(' + product.image.image_url + ')' }} />
                                </div>
                                <div className="box-product__content">
                                    <div className="box-product__text">
                                        {product.preTitle && (
                                            <div className="box-product__pre-title">{product.preTitle}</div>
                                        )}
                                        <div className="box-product__title">{product.name}</div>
                                        {product.description && (
                                            <div className="box-product__description">
                                                <RenderHTML nowrapper={true} html={product.description} />
                                            </div>
                                        )}
                                    </div>
                                    {product.swatches && (
                                        <ul className="box-product__swatches">
                                            {Object.values(product.swatches).map((swatches) => {
                                                return Object.values(swatches).map((swatch, swatchIndex) => {
                                                    if (swatch.value) {
                                                        return (
                                                            <li key={swatchIndex}>
                                                                <span style={{ background: swatch.value }} />
                                                            </li>
                                                        );
                                                    }
                                                    return <React.Fragment key={swatchIndex} />;
                                                });
                                            })}
                                        </ul>
                                    )}
                                </div>
                                {product.meta && (
                                    <ul className="box-product__meta">
                                        {product.meta.map(
                                            (item) =>
                                                item.value && (
                                                    <li key={`${item.icon}${item.value}`}>
                                                        <span className="icon">
                                                            <Img iconName={`icon_${item.icon}`} alt={item.value} />
                                                        </span>
                                                        {item.value && <span className="label">{item.value}</span>}
                                                    </li>
                                                ),
                                        )}
                                    </ul>
                                )}
                                <div className="box-product__prices">
                                    <div className="box-product__prices__content in-columns">
                                        {product.priceOld && (
                                            <div
                                                className={`box-product__price ${
                                                    product.isSpecialPriceDiscount ? '' : 'sale'
                                                }`}
                                            >
                                                <span className="box-product__price-label">
                                                    {product.eStorePriceLabel}
                                                </span>
                                                {product.price}
                                            </div>
                                        )}
                                        {!product.priceOld && <div className="box-product__price">{product.price}</div>}
                                        {product.priceOld && (
                                            <div className="box-product__price old">
                                                <span className={'box-product__price-label'}>
                                                    {product.storePriceLabel}
                                                </span>
                                                {product.priceOld}
                                            </div>
                                        )}
                                    </div>
                                    <ProductActions product={product} assets={props.assets} />
                                </div>
                            </div>
                            {useListView && <ListProductTecDocAttributes product={product} />}
                        </div>
                    ))}
            </div>
        </React.Fragment>
    );
};

export default Products;
