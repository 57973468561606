import * as React from 'react';
import Overlay from 'automaailm-ui/lib/Components/Overlay/index';
import { useTranslation } from 'react-i18next';
import useOverlays from '../../../components/overlay/Overlay';

interface IProps {
    index: string;
}

const LoadingOverlay = (props: IProps) => {
    const { t } = useTranslation();
    const { overlays, closeOverlay } = useOverlays();

    return (
        <React.Fragment>
            <Overlay
                isOpen={overlays.indexOf(props.index) !== -1}
                layout="dialog"
                doClose={(e) => {
                    e.preventDefault();
                    closeOverlay(props.index);
                }}
                dialogConfig={{
                    description: <React.Fragment>{t('checkout.Loading message')}</React.Fragment>,
                    buttons: [
                        {
                            title: t('checkout.Understood'),
                            intent: 'primary',
                            layout: 'link',
                            onClick: (e) => {
                                e.preventDefault();
                                closeOverlay(props.index);
                            },
                        },
                    ],
                }}
            />
        </React.Fragment>
    );
};
export default LoadingOverlay;
