import * as React from 'react';
import { IAvailabilityResponse } from 'components/Catalog/Product/Interfaces/IAvailability';
import { useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import productAvailabilityRequest from 'data/requests/product/availability/productAvailabilityRequest';
import StoreInfoOverlay from 'components/Catalog/Product/Overlay/StoreInfoOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { IStoreState } from '../../../helpers/rootReducer';

interface IProps {
    items: IAvailabilityResponse[];
    availabilityLabel: string;
    productId?: string;
    emailLabel: string;
    phoneLabel: string;
    openingHoursLabel: string;
    addressLabel: string;
}

const Availability = (props: IProps) => {
    const { items, availabilityLabel, emailLabel, phoneLabel, openingHoursLabel, addressLabel, productId } = props;
    const [availability, setAvailability] = useState(items);
    const [{}, availabilityRequest] = useMutation((id: string) => productAvailabilityRequest(id));
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const dispatch = useDispatch();
    const openShopInfo = (name: string) => {
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'shopinfo' + name }));
    };

    useEffect(() => {
        if (productId) {
            availabilityRequest(productId).then((response) => setAvailability(response.body));
        }
    }, [productId]);

    return (
        <React.Fragment>
            {availability && availability.length > 0 && (
                <React.Fragment>
                    <hr />
                    <p>
                        <b>{availabilityLabel}</b>
                    </p>
                    <ul className="product-availability">
                        {availability.map((item) => {
                            const hasContactInfo = item.phone || item.openingHours || item.email;

                            const handleShopInfoClick = () => {
                                if (hasContactInfo) {
                                    openShopInfo(item.name);
                                }
                            };

                            return (
                                <li className={item.class} key={item.name}>
                                    <a href="#" onClick={handleShopInfoClick}>
                                        {item.name}
                                    </a>{' '}
                                    - <span className="status">{item.message}</span>
                                    {item.label && <a>{item.label}</a>}
                                </li>
                            );
                        })}
                    </ul>
                    {availability.map((item) => {
                        return item.phone || item.openingHours || item.email || item.address ? (
                            <StoreInfoOverlay
                                key={'shopinfo' + item.name}
                                openOverlays={openOverlays}
                                item={item}
                                addressLabel={addressLabel}
                                phoneLabel={phoneLabel}
                                openingHoursLabel={openingHoursLabel}
                                emailLabel={emailLabel}
                            />
                        ) : (
                            <React.Fragment />
                        );
                    })}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Availability;
