import { request } from 'data/requests/request';

const modelRequest = (manufacturerId: string) =>
    request({
        type: 'modelResponse',
        url: 'carSearch/search/model',
        notApi: true,
        data: {
            manufacturerId: manufacturerId,
        },
        force: true,
    });

export default modelRequest;
