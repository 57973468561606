import { request } from 'data/requests/request';

const tecDocProductAttributesRequest = (tecDocArticleId?: string, carId?: string) => {
    return request({
        type: 'tecDocProductAttributes',
        url: `tecDocProductList/attributes/index/carId/${carId}/tecDocArticleId/${tecDocArticleId}`,
        notApi: true,
    });
};

export default tecDocProductAttributesRequest;
