import { request } from 'data/requests/request';

const newsletterRequest = (email: string, agreeToCompanyTerms: boolean, agreeToMarketingTerms: boolean) =>
    request({
        type: 'response',
        url: 'newsletter/newsletter/subscribe',
        data: { email, agreeToCompanyTerms, agreeToMarketingTerms },
        method: 'POST',
        notApi: true,
    });

export default newsletterRequest;
