import React from 'react';
import Button from 'automaailm-ui/lib/Components/Button/index';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import { useMutation } from 'redux-query-react';
import vehicleAddRequest from 'data/requests/customer/VehicleAddRequest';
import Toaster from 'automaailm-ui/lib/Components/Toaster/index';

interface IProps {
    label: string;
    selectedVehicle?: IVehicle;
}

const AddButton = (props: IProps) => {
    const { label, selectedVehicle } = props;
    const [{}, addRequest] = useMutation(() => vehicleAddRequest(selectedVehicle));

    const addVehicle = () => {
        addRequest().then((response) => {
            Toaster.addToast({
                intent: response.status === 200 ? 'success' : 'danger',
                text: response?.body?.message,
                asHtml: true,
            });

            if (response.status === 200) {
                window.location.reload();
            }
        });
    };

    return (
        <div className="column action">
            <Button
                disabled={!selectedVehicle?.type}
                title={label}
                intent="primary"
                onClick={() => {
                    addVehicle();
                }}
            />
        </div>
    );
};

export default AddButton;
