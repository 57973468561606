import React from 'react';
import HeadingWithButtons from 'automaailm-ui/lib/Components/HeadingWithButtons/index';
import ControlInput from 'automaailm-ui/lib/Components/ControlInput/index';
import Icon from 'automaailm-ui/lib/Components/Icon/index';
import { ITranslations } from 'components/CategoryList/CategoryList';

interface IProps {
    query: string;
    setQuery: (query: string) => void;
    labels: ITranslations;
}

const CategoryFilter = (props: IProps) => {
    const { query, setQuery, labels } = props;

    return (
        <HeadingWithButtons
            title={labels.chooseSparePartCategory}
            level={2}
            addOn={
                <div className="category-select__search">
                    <ControlInput
                        addOnFront={<Icon width={16} height={16} kind="search" />}
                        value={query}
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        placeholder={labels.searchCategory}
                    />
                </div>
            }
        />
    );
};

export default CategoryFilter;
