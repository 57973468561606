import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import saveCookieRequest from 'data/requests/cookie/saveCookieRequest';
import Toaster from 'automaailm-ui/lib/Components/Toaster/index';
import CookieSettings from 'components/CookieSettings';
import Cookies from 'js-cookie';

export interface ICookieProps {
    config: ICookieConfig;
}

export interface ICookieConfig {
    modalTitle: string;
    introText: string;
    settingsLabel: string;
    agreeToAllLabel: string;
    notAgreeLabel: string;
    agreeToSelectedLabel: string;
    cookieSelection: ICookieSelection[];
}

export interface ICookieSelection {
    id: number;
    code: string;
    name: string;
    description: string;
    is_required: boolean;
    isChecked: boolean;
}

const hasGdprCookieToken = (): boolean => {
    return !!Cookies.get('gdpr_cookie_token');
};

const Cookie: React.FunctionComponent<ICookieProps> = (props: ICookieProps) => {
    if (hasGdprCookieToken()) {
        return <React.Fragment />;
    }

    const [{}, saveRequest] = useMutation((cookies: ICookieSelection[]) => saveCookieRequest(cookies));

    const [showSettings, setShowSettings] = useState(false);

    const handleAcceptAll = useCallback(async () => {
        const cookiesToSend = props.config.cookieSelection.map((cookieSelection: ICookieSelection) => {
            cookieSelection.isChecked = true;
            return cookieSelection;
        });
        saveData(cookiesToSend);
    }, []);

    const handleDenyAll = useCallback(async () => {
        const cookiesToSend = props.config.cookieSelection.map((cookieSelection: ICookieSelection) => {
            if (!cookieSelection.is_required) {
                cookieSelection.isChecked = false;
            } else {
                cookieSelection.isChecked = true;
            }
            return cookieSelection;
        });
        saveData(cookiesToSend);
    }, []);

    const saveData = (cookiesToSend: ICookieSelection[]) => {
        saveRequest(cookiesToSend).then((response) => {
            Toaster.addToast({
                intent: response.body.errors ? 'danger' : 'success',
                text: response.body.message,
                asHtml: true,
            });
            if (!response.body.errors) {
                window.location.reload(true);
            }
        });
    };

    useEffect(() => {
        if (showSettings) {
            document.body.closest('html')?.classList.add('overlayopen');
        } else {
            document.body.closest('html')?.classList.remove('overlayopen');
        }
        return function cleanup() {
            document.body.closest('html')?.classList.remove('overlayopen');
        };
    }, [showSettings]);

    return !showSettings ? (
        <React.Fragment>
            <div className="cookiemessage__limiter">
                <div dangerouslySetInnerHTML={{ __html: props.config.introText }} className="content" />
                <div className="actions">
                    <a href="javascript:void(0)" onClick={() => handleAcceptAll()}>
                        {props.config.agreeToAllLabel}
                    </a>
                    <a href="javascript:void(0)" onClick={() => setShowSettings(!showSettings)}>
                        {props.config.settingsLabel}
                    </a>
                    <a href="javascript:void(0)" onClick={() => handleDenyAll()}>
                        {props.config.notAgreeLabel}
                    </a>
                </div>
            </div>
        </React.Fragment>
    ) : (
        <CookieSettings config={props.config} saveData={saveData} handleAcceptAll={handleAcceptAll} />
    );
};

Cookie.displayName = 'Cookie';

export default Cookie;
