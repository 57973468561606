import * as React from 'react';
import RenderHTML from 'automaailm-ui/lib/Components/RenderHTML/index';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import breadcrumbsRequest, { IBreadCrumbItem } from 'components/Catalog/Product/requests/breadcrumbsRequest';

interface IConfig {
    apiUrl: string;
    productId: string;
}

interface IProps {
    dom: string;
    config: IConfig;
}

const mapStateToProps = (state) => {
    return {
        breadcrumbsResponse: state.entities.breadcrumbsResponse as IBreadCrumbItem[],
    };
};

const ProductDetailBreadcrumbs = (props: IProps) => {
    const { config, dom } = props;
    const { breadcrumbsResponse } = useSelector(mapStateToProps);
    const [{}] = useRequest(breadcrumbsRequest(config.productId, config.apiUrl));

    return (
        <React.Fragment>
            {(dom && !breadcrumbsResponse) ||
                (breadcrumbsResponse.length < 1 && <RenderHTML html={dom} nowrapper={true} />)}
            {breadcrumbsResponse && (
                <ul className="path">
                    {breadcrumbsResponse.map((breadcrumbsResponseItem, key) => (
                        <React.Fragment key={key}>
                            <li className="item">
                                {breadcrumbsResponseItem.url && (
                                    <a href={breadcrumbsResponseItem.url}>{breadcrumbsResponseItem.label}</a>
                                )}
                                {!breadcrumbsResponseItem.url && (
                                    <strong>
                                        <span>{breadcrumbsResponseItem.label}</span>
                                    </strong>
                                )}
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
            )}
        </React.Fragment>
    );
};

export default ProductDetailBreadcrumbs;
