import * as React from 'react';
import Overlay from 'automaailm-ui/lib/Components/Overlay';

interface IProps {
    title: string;
    buttonOk: string;
    buttonCancel: string;
    description: string;
    onAccept: () => void;
    onClose: () => void;
}

const OverlayConfirm = (props: IProps) => {
    const { onAccept, onClose } = props;

    return (
        <Overlay
            isOpen={true}
            layout="dialog"
            title={props.title}
            dialogConfig={{
                description: props.description,
                buttons: [
                    {
                        title: props.buttonOk,
                        intent: 'danger',
                        onClick: onAccept,
                    },
                    {
                        title: props.buttonCancel,
                        intent: 'discrete',
                    },
                ],
            }}
            doClose={() => onClose()}
        />
    );
};

export default OverlayConfirm;
