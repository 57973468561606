import React from 'react';
import { IModel } from 'components/SearchBlock/CarSearch/CarSelectSearch';
import { ITranslations, IUrls } from 'components/SearchBlock/SearchBlock';
import LicencePlateSearch from 'components/SearchBlock/CarSearch/LicencePlate/LicencePlateSearch';
import BatteryCarSelectSearch from 'components/SearchBlock/BatterySearch/BatteryCarSelectSearch';

export interface IVehicle {
    manufacturer?: IModel;
    model?: IModel;
    type?: IModel;
    licence?: string;
}

interface IProps {
    viewType: string;
    labels: ITranslations;
    urls: IUrls;
    selectedVehicle?: IVehicle;
    setSelectedVehicle: (vehicle?: IVehicle) => void;
}

const BatterySearchBlock = (props: IProps) => {
    const { viewType, labels, selectedVehicle, setSelectedVehicle, urls } = props;

    return (
        <React.Fragment>
            <LicencePlateSearch
                urls={urls}
                viewType={viewType}
                labels={labels}
                iconName={'license_est'}
                selectedVehicle={selectedVehicle}
                setSelectedVehicle={setSelectedVehicle}
            />
            <BatteryCarSelectSearch
                labels={labels}
                urls={urls}
                selectedVehicle={selectedVehicle}
                setSelectedVehicle={setSelectedVehicle}
            />
        </React.Fragment>
    );
};

export default BatterySearchBlock;
