import React from 'react';
import TireSize from 'components/SearchBlock/TireSearch/LicencePlate/Overlay/TireSize';
import { Tire } from 'components/SearchBlock/TireSearch/LicencePlate/Overlay/TireOverlay';

interface IProps {
    tire: Tire;
}

const TireTableRow = (props: IProps) => {
    const { tire } = props;

    return (
        <tr>
            <TireSize tire={tire} />
            {tire.seasons.map((season) => {
                return (
                    <td className="center bold" key={`${season.season}-${tire.name}`}>
                        {season.count > 0 && <a href={season.href}>{season.count}</a>}
                        {!season.count && '-'}
                    </td>
                );
            })}
        </tr>
    );
};

export default TireTableRow;
