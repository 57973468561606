import ControlSelect from 'automaailm-ui/lib/Components/ControlSelect/index';
import React from 'react';

interface IProps {
    placeholder: string;
}

const DisabledSelect = (props: IProps) => {
    const { placeholder } = props;

    return (
        <ControlSelect value={''} disabled={true} placeholder={placeholder}>
            <option value="" />
        </ControlSelect>
    );
};

export default DisabledSelect;
