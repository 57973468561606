import * as React from 'react';
import { IShippingAddress } from '../../interfaces/checkout/address/IShippingInformationData';
import { IAddress, IGuestAddress } from '../../interfaces/checkout/customer/ICustomer';
import { dummyFields } from '../../components/address/dummyFields';

const SelectedAddressToPostAddress = (selectedAddress: IAddress): IShippingAddress => {
    return {
        customerAddressId: selectedAddress.id?.toString(),
        countryId: selectedAddress.country_id || dummyFields.country_id,
        regionId: selectedAddress.region_id || selectedAddress.region?.region_id || dummyFields.region_id,
        regionCode: selectedAddress.region?.region_code,
        region: selectedAddress.region?.region,
        customerId: selectedAddress.customer_id?.toString(),
        street: [selectedAddress.street[0] || dummyFields.street[0]],
        telephone: selectedAddress.telephone
            ? `${selectedAddress.phoneCode}${selectedAddress.telephone}`
            : dummyFields.telephone,
        postcode: selectedAddress.postcode || dummyFields.postcode,
        city: selectedAddress.city || dummyFields.city,
        firstname: selectedAddress.firstname || dummyFields.firstname,
        lastname: selectedAddress.lastname || dummyFields.lastname,
        company: selectedAddress.company,
        vatId: selectedAddress.vat_id,
        fax: selectedAddress.fax,
        saveInAddressBook: selectedAddress.saveInAddressBook ? 1 : 0,
    };
};

export default SelectedAddressToPostAddress;
