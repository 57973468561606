import { request } from 'data/requests/request';

const vehicleIndexRequest = () =>
    request({
        type: 'vehicleIndex',
        url: 'vehicle/vehicle/index',
        method: 'GET',
        notApi: true,
    });

export default vehicleIndexRequest;
