import React from 'react';
import ManufacturerSelect from 'components/SearchBlock/CarSearch/SelectFields/ManufacturerSelect';
import DisabledSelect from 'components/SearchBlock/CarSearch/SelectFields/Types/DisabledSelect';
import ModelSelect from 'components/SearchBlock/CarSearch/SelectFields/ModelSelect';
import TypeSelect from 'components/SearchBlock/CarSearch/SelectFields/TypeSelect';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import { ITranslations, IUrls } from 'components/SearchBlock/SearchBlock';
import SearchButton from 'components/SearchBlock/SearchButton';

export interface IModel {
    id: string;
    label: string;
}

interface IProps {
    selectedVehicle?: IVehicle;
    setSelectedVehicle: (vehicle?: IVehicle) => void;
    labels: ITranslations;
    urls: IUrls;
}

const BatteryCarSelectSearch = (props: IProps) => {
    const { selectedVehicle, setSelectedVehicle, labels, urls } = props;

    let href = '';
    if (selectedVehicle?.type?.id) {
        href = urls.batteryList.replace('carIdParameterToReplace', selectedVehicle.type.id);
    }

    return (
        <div className="box-search__group">
            <div className="box-search__label">{labels.searchByVehicle}</div>
            <div className="box-search__fields">
                <div className="column">
                    <ManufacturerSelect
                        selectedVehicle={selectedVehicle}
                        setSelectedVehicle={setSelectedVehicle}
                        placeholder={labels.manufacturer}
                    />
                </div>
                <div className="column">
                    <div className="control-holder">
                        {!selectedVehicle?.manufacturer && <DisabledSelect placeholder={labels.model} />}
                        {selectedVehicle && selectedVehicle.manufacturer && (
                            <ModelSelect
                                selectedVehicle={selectedVehicle}
                                setSelectedVehicle={setSelectedVehicle}
                                manufacturerId={selectedVehicle.manufacturer.id}
                                placeholder={labels.model}
                            />
                        )}
                    </div>
                </div>
                <div className="column">
                    <div className="control-holder">
                        {!selectedVehicle || (!selectedVehicle.model && <DisabledSelect placeholder={labels.motor} />)}
                        {selectedVehicle && selectedVehicle.manufacturer && selectedVehicle.model && (
                            <TypeSelect
                                selectedVehicle={selectedVehicle}
                                setSelectedVehicle={setSelectedVehicle}
                                manufacturerId={selectedVehicle.manufacturer.id}
                                modelId={selectedVehicle.model.id}
                                placeholder={labels.motor}
                            />
                        )}
                    </div>
                </div>
                <SearchButton label={labels.find} href={href} disabled={!selectedVehicle?.type} />
            </div>
        </div>
    );
};

export default BatteryCarSelectSearch;
