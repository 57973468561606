import * as React from 'react';
import { IConfigPrice } from 'components/Catalog/Product/Detail';
import RequiredProduct from 'components/Catalog/Product/RequiredProduct';

interface IProps {
    price: IConfigPrice;
    eStorePriceLabel: string;
    storePriceLabel: string;
    oldPrice?: string;
    productId?: string;
    isSpecialPriceDiscount?: boolean;
}

const Price = (props: IProps) => {
    const { price, oldPrice, isSpecialPriceDiscount, productId, eStorePriceLabel, storePriceLabel } = props;

    return (
        <React.Fragment>
            {oldPrice && (
                <React.Fragment>
                    <li className="primary">
                        <div className="label">{eStorePriceLabel}</div>
                        <div className={`price  ${isSpecialPriceDiscount ? '' : 'sale'}`}>{price.valueLabel}</div>
                    </li>
                    <li>
                        <div className="label">{storePriceLabel}</div>
                        <div className="price">{oldPrice}</div>
                    </li>
                </React.Fragment>
            )}
            {!oldPrice && <div className="price">{price.valueLabel}</div>}
            <RequiredProduct productId={productId} />
        </React.Fragment>
    );
};

export default Price;
