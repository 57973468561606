import * as React from 'react';
import { ICartTotals } from 'components/Cart/Interfaces/ICartTotals';
import Coupon from 'components/Cart/Coupon';

export interface IProps {
    cart: ICartTotals;
    cartContainer?: string;
    emptyMessage?: string;
}

const CartSidebar = (props: IProps) => {
    const { cart, cartContainer, emptyMessage } = props;
    if (!cart || emptyMessage) {
        return <React.Fragment />;
    }
    return (
        <div className={'layout-cart__sidebar'}>
            <div className={'cart-block primary'}>
                <h2>{cart.totalsLabel}</h2>
                <ul className={'cart-prices'}>
                    <li>
                        <span className={'label'}>{cart.subtotal.label}:</span>
                        <span className={'value'}>{cart.subtotal.value}</span>
                    </li>
                    <li>
                        <span className={'label'}>{cart.withoutTax.label}:</span>
                        <span className={'value'}>{cart.withoutTax.value}</span>
                    </li>
                    {cart.taxes &&
                        cart.taxes.map((item, key) => (
                            <li key={key}>
                                <span className={'label'}>{item.label}:</span>
                                <span className={'value'}>{item.value}</span>
                            </li>
                        ))}
                    <li className={'primary'}>
                        <span className={'label'}>{cart.grandTotal.label}:</span>
                        <span className={'value'}>{cart.grandTotal.value}</span>
                    </li>
                </ul>
                <ul className={'cart-buttons'}>
                    <li>
                        <a className={'cart-button primary'} href={cart.checkoutUrl.value}>
                            {cart.checkoutUrl.label}
                        </a>
                    </li>
                    {cart.returnUrl && (
                        <li>
                            <a className={'cart-button'} href={cart.returnUrl.value}>
                                {cart.returnUrl.label}
                            </a>
                        </li>
                    )}
                </ul>
                {cartContainer && <p dangerouslySetInnerHTML={{ __html: cartContainer }} />}
            </div>
            <Coupon quoteId={cart.quoteId} labels={cart.couponLabels} />
        </div>
    );
};

export default CartSidebar;
