import React, { useState } from 'react';
import { ITranslations, IUrls } from 'components/SearchBlock/SearchBlock';
import TireSearchDataSelect from 'components/SearchBlock/TireSearch/TireSearchDataSelect';
import { useRequest } from 'redux-query-react';
import tireOptionsRequest from 'data/tireSearch/TireOptionsRequest';
import { useSelector } from 'react-redux';
import TireLicenceSearch from 'components/SearchBlock/TireSearch/LicencePlate/TireLicenceSearch';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';

interface IProps {
    urls: IUrls;
    labels: ITranslations;
    selectedVehicle?: IVehicle;
    setSelectedVehicle: (vehicle?: IVehicle) => void;
}

export interface ITire {
    width?: string;
    height?: string;
    diameter?: string;
    season?: string;
}

export interface ITireOptions {
    dimensions: {
        height: Array<string>;
        width: Array<string>;
        diameter: Array<string>;
    };
    seasons: Array<string>;
}

const mapStateToProps = (state) => {
    return {
        tireOptions: state.entities.tireOptionsResponse as ITireOptions,
    };
};

const TireSearchBlock = (props: IProps) => {
    const { labels, selectedVehicle, setSelectedVehicle, urls } = props;
    const [selectedTire, setSelectedTire] = useState<ITire>();

    useRequest(tireOptionsRequest());
    const { tireOptions } = useSelector(mapStateToProps);

    return (
        <React.Fragment>
            <TireLicenceSearch
                urls={urls}
                labels={labels}
                setSelectedVehicle={setSelectedVehicle}
                selectedVehicle={selectedVehicle}
            />
            <TireSearchDataSelect
                urls={urls}
                selectedTire={selectedTire}
                setSelectedTire={setSelectedTire}
                labels={labels}
                options={tireOptions}
            />
        </React.Fragment>
    );
};

export default TireSearchBlock;
