import React from 'react';
import { Category, ITranslations } from 'components/CategoryList/CategoryList';
import CategoryFilterResultCategory from 'components/CategoryList/CategoryFilterResultCategory';
import { FilterHelper } from 'components/CategoryList/Helper/FilterHelper';

interface IProps {
    categories: Array<Category>;
    query: string;
    carId: string;
    labels: ITranslations;
}

const CategoryFilterResult = (props: IProps) => {
    const { categories, query, carId, labels } = props;

    const filteredCategories = new FilterHelper().filterCategories(query, categories);
    return (
        <div className="category-tree__group">
            {filteredCategories.map((category, index) => {
                return (
                    <CategoryFilterResultCategory
                        category={category}
                        index={index}
                        carId={carId}
                        key={category.assemblyGroupNodeId + category.assemblyGroupName}
                    />
                );
            })}
            {filteredCategories.length === 0 && (
                <div className="layout-products__list">
                    <div className="generic-message">
                        <div className="generic-message__limiter">
                            <div className="generic-message__title">{labels.noCategoriesFound}</div>
                            <div className="generic-message__description">{labels.tryAnotherKeyword}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CategoryFilterResult;
