import * as React from 'react';
import { ITecDocAttribute, Product } from '../Interfaces/List/IListResponse';

interface IProps {
    product: Product;
}

const ListProductTecDocAttributes = (props: IProps) => {
    const { product } = props;

    return (
        <React.Fragment>
            {product && product.tecDocAttributes && product.tecDocAttributes.length && (
                <div className="box-product__details">
                    <ul className="box-product__attributes">
                        {product.tecDocAttributes.map((attribute: ITecDocAttribute) => {
                            return (
                                <li>
                                    <span className="box-product__attributes__label">{attribute.attributeName}:</span>
                                    <span className="box-product__attributes__value">{attribute.attributeValue}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </React.Fragment>
    );
};

export default ListProductTecDocAttributes;
