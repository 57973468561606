import * as React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import ListView from 'components/Catalog/Product/ListView';

export interface ITranslations {
    sendInquiry: string;
    outOfStock: string;
    yourName: string;
    yourEmail: string;
    yourTelephoneNumber: string;
    carLicenceNumber: string;
    yourInquiry: string;
    send: string;
    sendCopyToMyAddress: string;
    requiredField: string;
    invalidEmail: string;
    invalidTelephone: string;
}

interface IProps {
    dom: string;
    config?: {
        labels: ITranslations;
        actionUrl: string;
    };
}

const ListViewWrapper = (props: IProps) => {
    const { dom, config } = props;

    return (
        <React.Fragment>
            <BrowserRouter>
                <Switch>
                    <Route path="/:level1?/:level2?/:level3?/:level4?/:level5?/">
                        <ListView
                            dom={dom}
                            isTecDocView={!dom.length}
                            labels={config?.labels}
                            actionUrl={config?.actionUrl}
                        />
                    </Route>
                </Switch>
            </BrowserRouter>
        </React.Fragment>
    );
};

export default ListViewWrapper;
