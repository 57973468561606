import * as React from 'react';
import { useEffect, useState } from 'react';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import Product from 'components/Cart/Product/Product';

export interface ICartProductsProps {
    products: ICartProduct[];
    quoteId: string;
    emptyMessage?: string;
}

const CartProducts = (props: ICartProductsProps) => {
    const { products, quoteId, emptyMessage } = props;
    const [error, setError] = useState<string>();
    if (!products) {
        return <React.Fragment />;
    }
    const effect = () => {
        setError(undefined);
    };
    useEffect(() => {
        window.addEventListener('cart-altered', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    }, [error]);
    return (
        <React.Fragment>
            <div className={'layout-cart__main'}>
                {error && <div className="cart-callout intent-danger">{error}</div>}
                {emptyMessage && !products.length && <div className="cart-callout">{emptyMessage}</div>}
                <table className={'cart-table'}>
                    <tbody>
                        {quoteId &&
                            products.map((product: ICartProduct, key) => (
                                <Product product={product} key={key} quoteId={quoteId} setError={setError} />
                            ))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default CartProducts;
