import React from 'react';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import { ITranslations } from 'components/SearchBlock/SearchBlock';

interface IProps {
    labels: ITranslations;
    title: string;
    licence: string;
    cars: Array<any>;
    setSelectedVehicle: (vehicle: IVehicle) => void;
    hideResults: () => void;
}

const OverlayDetails = (props: IProps) => {
    const { title, cars, setSelectedVehicle, licence, hideResults, labels } = props;

    return (
        <div>
            <p>
                <b>{title}</b>
            </p>
            <div className="table">
                <table>
                    <thead>
                        <tr>
                            <th>{labels.motor}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cars.map((car) => {
                            return (
                                <tr key={car.title + car.carId}>
                                    <td key={car.carId + car.title}>
                                        <a
                                            key={car.carId}
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedVehicle({
                                                    manufacturer: {
                                                        id: car.manuId,
                                                        label: car.manuName,
                                                    },
                                                    model: {
                                                        id: car.modId,
                                                        label: car.modelName,
                                                    },
                                                    type: {
                                                        id: car.carId,
                                                        label: car.typeTitle,
                                                    },
                                                    licence,
                                                });
                                                hideResults();
                                            }}
                                        >
                                            {car.typeTitle}
                                        </a>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OverlayDetails;
