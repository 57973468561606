import React from 'react';
import { Category } from 'components/CategoryList/CategoryList';
import Subcategory from 'components/CategoryList/Subcategory';

interface IProps {
    categories?: Array<Category>;
    carId: string;
}

const CategoryFilterResultSubcategory = (props: IProps) => {
    const { carId, categories } = props;

    return (
        <ul>
            {categories &&
                categories.map((category, index) => {
                    const id = `cat-${index}${category.assemblyGroupNodeId}`;
                    if (category.hasChilds) {
                        return (
                            <li key={id}>
                                <div className="item-row">
                                    <label htmlFor={id}>{category.assemblyGroupName}</label>
                                </div>
                                <CategoryFilterResultSubcategory categories={category.children} carId={carId} />
                            </li>
                        );
                    } else {
                        return (
                            <Subcategory
                                id={category.assemblyGroupNodeId}
                                name={category.assemblyGroupName}
                                key={id}
                                href={category.href}
                            />
                        );
                    }
                })}
        </ul>
    );
};

export default CategoryFilterResultSubcategory;
