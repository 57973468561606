import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import {
    IFinanceModel,
    IFinanceProvider,
    IFinanceResponse,
    IValueLabel,
} from 'components/Financing/Interfaces/IFinance';
import { useMutation } from 'redux-query-react';
import calculateRequest from 'data/requests/product/financial/calculateRequest';
import { debounce } from 'lodash';
import { IConfig } from 'components/GeneralForm/Interfaces/IConfig';
import classNames from 'classnames';
import CheckboxWithLabel from 'automaailm-ui/lib/Components/CheckboxWithLabel/index';
import LayoutForm from 'automaailm-ui/lib/Layouts/LayoutForm/index';
import Datepicker from 'automaailm-ui/lib/Components/Datepicker';
import moment from 'moment';
import { request } from 'data/requests/request';
import Toaster from 'automaailm-ui/lib/Components/Toaster';
import { extractErrors } from '../../helpers/request/extractErrors';

interface IProps {
    config: IConfig;
    productId?: string;
}
interface IStates {
    code: string;
    value: string;
}

const GeneralForm = (props: IProps) => {
    const { config } = props;
    const initalStates: IStates[] = [];
    config.fields.forEach((field) => {
        if (field.value) {
            initalStates.push({ code: field.code, value: field.value });
        }
    });
    const [states, setStates] = useState<IStates[]>(initalStates);
    const [erroredFields, setErroredFields] = useState<string[]>();
    const [formDisabled, setFormDisabled] = useState<boolean>(false);

    const [{}, postRequest] = useMutation(() => {
        setFormDisabled(true);
        const data = {};
        data[`ref`] = (window as any).ref;
        states?.forEach((state) => {
            data[`${state.code}`] = state.value;
        });

        return request({
            method: 'POST',
            type: 'request',
            url: config.url,
            data,
        });
    });

    const setValue = (code: string, value?: string) => {
        const existingStates = states?.filter((state) => state.code !== code) ?? [];
        if (value) {
            existingStates.push({ code, value });
        }
        setStates(existingStates);
    };

    const initRequest = () => {
        setErroredFields([]);
        postRequest().then((response) => {
            setFormDisabled(false);
            if (response.status !== 200 || !response.body.success) {
                const message =
                    !response.body?.message || response.body?.message?.includes('fieldName')
                        ? config.errorText
                        : response.body?.message;
                Toaster.addToast({
                    intent: 'danger',
                    text: message ?? config.errorText,
                });
                if (response.body?.parameters) {
                    setErroredFields(response.body?.parameters?.fieldName);
                } else if (response.body?.errors) {
                    const errors = response.body.errors.map((error) => {
                        return error.parameters.fieldName;
                    });
                    setErroredFields(errors);
                }
            }
            if (response.body.success) {
                Toaster.addToast({
                    intent: 'success',
                    text: response.body.message,
                });
            }
            if (response.body.redirect) {
                setTimeout(() => (window.location.href = response.body.redirect), 3000);
            }
        });
    };

    return (
        <React.Fragment>
            {config.fields && (
                <React.Fragment>
                    <ul className={'form-list'}>
                        {config.fields.map((field, key) => (
                            <React.Fragment key={key}>
                                {field.type === 'hidden' && (
                                    <input
                                        type={'hidden'}
                                        name={field.code}
                                        value={states?.find((state) => state.code === field.code)?.value}
                                        id={field.code}
                                    />
                                )}
                                {field.type !== 'hidden' && (
                                    <li key={field.code}>
                                        <label
                                            htmlFor="field-name"
                                            className={classNames({ required: field.required })}
                                        >
                                            {field.required && <em>*</em>}
                                            {field.type !== 'agreement' && (
                                                <React.Fragment>{field.label}</React.Fragment>
                                            )}
                                        </label>
                                        <div className="input-box">
                                            {field.type === 'agreement' && (
                                                <CheckboxWithLabel
                                                    hasError={erroredFields?.includes(field.code)}
                                                    label={field.label}
                                                    checked={
                                                        states?.find((state) => state.code === field.code)?.value ===
                                                        '1'
                                                    }
                                                    onChange={(e) => {
                                                        setValue(
                                                            field.code,
                                                            !(
                                                                states?.find((state) => state.code === field.code)
                                                                    ?.value === '1'
                                                            )
                                                                ? '1'
                                                                : undefined,
                                                        );
                                                    }}
                                                />
                                            )}
                                            {field.type === 'input' && (
                                                <input
                                                    className={classNames('control-input', {
                                                        'has-error': erroredFields?.includes(field.code),
                                                    })}
                                                    type={field.isNumberType ? 'number' : ''}
                                                    name={field.code}
                                                    placeholder={
                                                        states?.find((state) => state.code === field.code)?.value
                                                    }
                                                    id={field.code}
                                                    onChange={(e) => {
                                                        setValue(field.code, e.target.value);
                                                    }}
                                                />
                                            )}
                                            {field.type === 'select' && (
                                                <select
                                                    className={classNames('control-input', {
                                                        'has-error': erroredFields?.includes(field.code),
                                                    })}
                                                    data-key="product_list_order"
                                                    onChange={(e) => {
                                                        setValue(field.code, e.target.value);
                                                    }}
                                                >
                                                    {field.options &&
                                                        field.options.map((option, key) => (
                                                            <option
                                                                key={option.name + ' ' + option.code}
                                                                value={option.code}
                                                            >
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            )}
                                        </div>
                                    </li>
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                    <ul className={'form-buttons'}>
                        <li>
                            <button
                                type="submit"
                                className="form-buttons__button intent-primary"
                                onClick={() => (!formDisabled ? initRequest() : {})}
                            >
                                {config.applyLabel}
                            </button>
                        </li>
                    </ul>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
export default GeneralForm;
