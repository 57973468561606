export const setActiveItems = (): void => {
    document.querySelectorAll('.category-tree__group').forEach((element) => {
        Array.from(element.children).forEach((child) => {
            if (child.nodeName === 'UL') {
                Array.from(child.children).forEach((listElement) => {
                    const anchor = listElement.querySelector('a');
                    if (anchor) {
                        const href = anchor.getAttribute('href');
                        if (href && href === window.location.pathname) {
                            anchor.setAttribute('class', `${anchor.className} active`);
                            element.setAttribute('class', `${element.className} active`);
                            const input = element.querySelector('input');
                            if (input) {
                                input.checked = true;
                            }
                        }
                    }
                });
            }
        });
    });
};
