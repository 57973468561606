import React, { useEffect, useState } from 'react';
import { ITire } from 'components/SearchBlock/TireSearch/TireSearchBlock';
import TireDataSelect from 'components/SearchBlock/TireSearch/SelectFields/TireDataSelect';

interface IProps {
    selectedTire?: ITire;
    setSelectedTire: (tire?: ITire) => void;
    placeholder: string;
    options: Array<string>;
}

const DiameterSelect = (props: IProps) => {
    const { selectedTire, setSelectedTire, placeholder, options } = props;
    const [diameter, setDiameter] = useState<string>();

    useEffect(() => {
        setSelectedTire({ ...selectedTire, diameter });
    }, [diameter]);

    return (
        <TireDataSelect
            placeholder={placeholder}
            options={options}
            attribute={selectedTire?.diameter}
            setAttribute={setDiameter}
        />
    );
};

export default DiameterSelect;
