import FormRow from 'automaailm-ui/lib/Components/FormRow/index';
import ControlSelect from 'automaailm-ui/lib/Components/ControlSelect/index';
import * as React from 'react';
import { IShippingMethod } from '../../../../interfaces/checkout/shipping/IShippingMethod';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { empty } from '../../../../helpers/empty';
import LayoutForm from 'automaailm-ui/lib/Layouts/LayoutForm/index';
import ProceedButton from '../../../../components/checkout/ProceedButton';
import { useRef } from 'react';
import RenderHTML from 'automaailm-ui/lib/Components/RenderHTML/index';
import { IAdditional, IAdditionalComponents } from '../../../../components/checkout/Shipping/Method/Additional';

export interface IAdditionalStorePickup extends IAdditional {
    store_id: string;
    store_name: string;
    shippingDescription: string;
}

interface IProps {
    selectedMethod?: IShippingMethod;
    proceedAction: any;
    resetAction: any;
    setAdditionalData: any;
    shippingAdditional: any | undefined;
    setShippingAdditional: any | undefined;
    continueDisabled: boolean;
    additionalComponents: IAdditionalComponents;
}

const StorePickup = (props: IProps) => {
    const {
        selectedMethod,
        proceedAction,
        resetAction,
        setAdditionalData,
        shippingAdditional,
        setShippingAdditional,
        continueDisabled,
        additionalComponents,
    } = props;
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState<any>();
    const [placeHolder, setPlaceHolder] = useState('');

    const defaultSelected = useRef(false);
    const storeId = useRef(false);

    if (selectedMethod && shippingAdditional) {
        const selectedDefaultItem = shippingAdditional[selectedMethod?.methodCode];
        if (selectedDefaultItem && !storeId.current) {
            defaultSelected.current = true;
            storeId.current = selectedDefaultItem.store_id;
        }
    }

    const selectItem = async (item) => {
        if (!defaultSelected.current) {
            defaultSelected.current = true;
        }

        await setSelectedItem(item);
    };
    return (
        <React.Fragment>
            <LayoutForm layout="vertical">
                <FormRow label={t('Choose Store')} required={true}>
                    <ControlSelect
                        disableSearch={false}
                        placeholder={placeHolder}
                        value={selectedItem ? selectedItem.label : ''}
                        onChange={(e) => {
                            resetAction();
                            selectItem(e.target.value);
                        }}
                    >
                        <option value="" />
                        {selectedMethod?.extra_data?.options?.map((location) => {
                            return (
                                <optgroup label={location.label} key={location.label}>
                                    {location.value &&
                                        location.value.map((store) => {
                                            if (!placeHolder && empty(store.value)) {
                                                setPlaceHolder(store.label);
                                            }
                                            if (
                                                empty(selectedItem?.value) &&
                                                storeId.current &&
                                                storeId.current === store.value
                                            ) {
                                                selectItem(store);
                                            }
                                            if (empty(store.value)) {
                                                store.label = t(store.label);
                                            }
                                            return (
                                                <option value={store} key={store.value}>
                                                    {store.label}
                                                </option>
                                            );
                                        })}
                                </optgroup>
                            );
                        })}
                    </ControlSelect>
                </FormRow>
            </LayoutForm>
            {selectedItem && selectedItem.address && (
                <div className="longtext">
                    <b>{selectedItem.label}</b>
                    <br />
                    {selectedItem.smallAddress && <small>{selectedItem.smallAddress}</small>}
                    {selectedItem.business_hours && <RenderHTML html={selectedItem.business_hours} />}
                    {selectedItem.url && (
                        <a href={selectedItem.url} target="_blank">
                            {t('Location and business hours')}
                        </a>
                    )}
                </div>
            )}
            {additionalComponents.courierInfo && additionalComponents.courierInfo.component}
            <ProceedButton
                proceedAction={() => {
                    if (selectedItem) {
                        const additionalData: IAdditionalStorePickup | undefined = {
                            store_id: selectedItem.value,
                            store_name: selectedItem.label,
                            shippingDescription: selectedItem.label,
                            courier_info: additionalComponents.courierInfo.exportField,
                        };
                        setAdditionalData(additionalData);
                        if (selectedMethod) {
                            shippingAdditional[selectedMethod.methodCode] = additionalData;
                            setShippingAdditional(shippingAdditional);
                        }
                        proceedAction();
                    }
                }}
                disabled={!selectedItem?.address || continueDisabled}
            />
        </React.Fragment>
    );
};
export default StorePickup;
