import * as React from 'react';
import { ICartButtonConfig } from 'components/Catalog/Product/Detail';
import Img from 'components/Image/Img';

interface IProps {
    buttonConfig: ICartButtonConfig;
    addToCart: () => {};
    isSalable: boolean;
}

const CartButton = (props: IProps) => {
    const { buttonConfig, addToCart, isSalable } = props;

    return (
        <React.Fragment>
            {buttonConfig && (
                <React.Fragment>
                    <li className="actions">
                        {isSalable && (
                            <a className={`product-pricing__button`} onClick={() => addToCart()}>
                                <Img iconName={'icon_cart'} />
                                <span className="label">{buttonConfig.addToCartLabel}</span>
                            </a>
                        )}
                        {!isSalable && (
                            <a className={`product-pricing__button disabled`}>
                                <span className="label">{buttonConfig.outOfStockLabel}</span>
                            </a>
                        )}
                    </li>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default CartButton;
