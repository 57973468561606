import React from 'react';

interface IProps {
    id: string;
    name: string;
    href?: string;
}

const Subcategory = (props: IProps) => {
    const { id, name, href } = props;

    return (
        <li key={`${name}${id}`}>
            <a href={href}>{name}</a>
        </li>
    );
};

export default Subcategory;
