import * as React from 'react';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import CartProducts from 'components/Cart/CartProducts';
import { ICartTotals } from 'components/Cart/Interfaces/ICartTotals';
import CartSidebar from 'components/Cart/CartSidebar';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';

export interface ICheckoutCartProps {
    config: {
        products: ICartProduct[];
        cartTotals: ICartTotals;
        cartContainer?: string;
        emptyMessage?: string;
    };
}
interface CartResponseType {
    products: ICartProduct[];
    cartTotals: ICartTotals;
}

const CheckoutCart = (props: ICheckoutCartProps) => {
    let { products, cartTotals, cartContainer, emptyMessage } = props.config;
    const [state, setState] = useState({ products, cartTotals });
    const [{}, cartRequest] = useMutation(() => request({ type: 'cart', url: 'cart/cart/index', notApi: true }));

    const effect = useCallback(async () => {
        const response = await cartRequest();
        const cartResponse: CartResponseType = response.body;
        setState({
            products: cartResponse.products,
            cartTotals: cartResponse.cartTotals,
        });
    }, [cartRequest]);

    /**
     * TODO rewrite to Store
     * @param props
     * @constructor
     */
    useEffect(() => {
        window.addEventListener('cart-altered', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    });
    return (
        <React.Fragment>
            <CartSidebar emptyMessage={emptyMessage} cart={state.cartTotals} cartContainer={cartContainer} />
            <CartProducts
                emptyMessage={emptyMessage}
                products={state.products}
                quoteId={state.cartTotals?.quoteId || ''}
            />
        </React.Fragment>
    );
};

export default CheckoutCart;
