import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import requiredProductRequest from 'data/requests/product/requiredProductRequest';

interface IProps {
    productId?: string;
}

const RequiredProduct = (props: IProps) => {
    const { productId } = props;
    const [{}, requiredProductRequestAction] = useMutation((id: string) => requiredProductRequest(id));

    const [html, setHtml] = useState('');

    useEffect(() => {
        if (productId) {
            requiredProductRequestAction(productId).then((response) => setHtml(response.body?.html));
        }
    }, [productId]);

    return (
        <React.Fragment>
            {html && (
                <React.Fragment>
                    <span dangerouslySetInnerHTML={{ __html: html }} className={'label'} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default RequiredProduct;
