import React from 'react';
import { Category, ITranslations } from 'components/CategoryList/CategoryList';
import CategoryListItem from 'components/CategoryList/CategoryListItem';

interface IProps {
    categories: Array<Category>;
    labels: ITranslations;
    carId: string;
}

const CategoryTree = (props: IProps) => {
    const { categories, carId } = props;

    return (
        <div className="category-tree">
            {categories.map((category, index) => (
                <CategoryListItem
                    category={category}
                    index={index}
                    carId={carId}
                    key={`${category.assemblyGroupNodeId}${category.assemblyGroupName}`}
                />
            ))}
        </div>
    );
};

export default CategoryTree;
