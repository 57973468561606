import React, { useEffect, useState } from 'react';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import { IMat } from '../MatSearchBlock';
import MatDataSelect from 'components/SearchBlock/MatSearch/SelectFields/MatDataSelect';
import { MatOptions } from 'components/SearchBlock/MatSearch/SelectFields/ManufacturerSelect';
import locationRequest from 'data/matSearch/LocationRequest';

interface IProps {
    placeholder: string;
    selectedMat?: IMat;
    setSelectedMat: (mat?: IMat) => void;
}

const mapStateToProps = (state) => {
    return {
        locations: state.entities.matLocationResponse as MatOptions[],
    };
};

const LocationSelect = (props: IProps) => {
    const { setSelectedMat, placeholder } = props;
    const [location, setSelectedLocation] = useState<undefined | string>(undefined);

    useEffect(() => {
        if (!location) {
            setSelectedMat({
                location: undefined,
                manufacturer: undefined,
                model: undefined,
                type: undefined,
            });
        } else {
            setSelectedMat({ location });
        }
    }, [location]);

    useRequest(locationRequest());
    const { locations } = useSelector(mapStateToProps);

    return (
        <MatDataSelect
            placeholder={placeholder}
            options={locations}
            setAttribute={setSelectedLocation}
            attribute={location}
        />
    );
};

export default LocationSelect;
