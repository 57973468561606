import * as React from 'react';
import RenderHTML from 'automaailm-ui/lib/Components/RenderHTML';

import './styles.scss';

export interface ILongPageProps {
    /* DOM to parse */
    dom: string;
    /** Unsafe config */
    config?: any;
}

const LongPage: React.FunctionComponent<ILongPageProps> = (props) => {
    // Main output
    return <RenderHTML html={props.dom} className="bootstrap" />;
};

LongPage.displayName = 'LongPage';

export default LongPage;
