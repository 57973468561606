import * as React from 'react';
import { ICartProduct, IWarrantyProduct } from 'components/Cart/Interfaces/ICartProduct';
import { useMutation } from 'redux-query-react';
import { useCallback } from 'react';
import { extractErrors } from '../../../helpers/request/extractErrors';
import { request } from 'data/requests/request';

export interface IProps {
    relatedProduct: IWarrantyProduct;
    mainProduct: ICartProduct;
    loading: boolean;
    setIsLoading: (loading: boolean) => void;
    setError: (error: string) => void;
}

const RelatedProduct = (props: IProps) => {
    const { relatedProduct, mainProduct, loading, setIsLoading, setError } = props;
    const [{}, warrantyRequest] = useMutation((data) =>
        request({
            method: 'POST',
            type: 'response',
            url: `cart/warranty/add/productId/${data.productId}/warrantyProductId/${data.warrantyProductId}/warrantyChildId/${data.warrantyChildId}`,
            notApi: true,
        }),
    );
    const alterProduct = useCallback(
        async (warrantyChildId: string) => {
            if (loading) {
                return;
            }
            setIsLoading(true);
            const data: any = {
                productId: mainProduct.id,
                warrantyProductId: relatedProduct.id,
                warrantyChildId,
            };
            const response = await warrantyRequest(data);
            setIsLoading(false);
            if (response.status === 200) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
            } else {
                const errors = extractErrors(response);
                if (errors && errors.error) {
                    setError(errors.error);
                }
            }
        },
        [warrantyRequest],
    );

    return (
        <React.Fragment>
            <tr className="cart-table-subproduct">
                <td className="cart-table-image">
                    <span className={'image'} style={{ backgroundImage: 'url(' + relatedProduct.image + ')' }} />
                </td>
                <td>
                    <p className="cart-table-title">{relatedProduct.name}</p>
                    {relatedProduct.callout && <p className="cart-callout intent-promotion">relatedProduct.callout</p>}
                    <ul className="cart-table-config">
                        {relatedProduct.items &&
                            relatedProduct.items.map((relatedSubItem) => {
                                const inputName = `section${mainProduct.id}${relatedSubItem.id}`;
                                return (
                                    <li key={`${relatedProduct.id}-${relatedSubItem.id}`}>
                                        <label>
                                            <input
                                                type="radio"
                                                name={inputName}
                                                checked={relatedSubItem.active}
                                                onChange={() => alterProduct(relatedSubItem.id)}
                                            />
                                            <span>
                                                {relatedSubItem.name} (+{relatedSubItem.price})
                                            </span>
                                        </label>
                                    </li>
                                );
                            })}
                    </ul>
                </td>
                <td>
                    <ul className="cart-table-price">
                        <li>
                            <span className="label">{mainProduct.price.label}:</span>{' '}
                            <span className="value sum">{relatedProduct.priceSingle}</span>
                        </li>
                        <li>
                            <span className="label">{mainProduct.qty.label}:</span>{' '}
                            <span className="value">{relatedProduct.qty}</span>
                        </li>
                        <li className="total">
                            <span className="label">{mainProduct.total.label}:</span>{' '}
                            <span className="value sum">{relatedProduct.priceRow}</span>
                        </li>
                    </ul>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default RelatedProduct;
