import React from 'react';
import { Category } from 'components/CategoryList/CategoryList';
import CategoryFilterResultSubcategory from 'components/CategoryList/CategoryFilterResultSubcategory';

interface IProps {
    category: Category;
    index: number;
    carId: string;
}

const CategoryFilterResultCategory = (props: IProps) => {
    const { category, index, carId } = props;
    const id = `cat-${index}${category.assemblyGroupNodeId}${carId}`;

    return (
        <div className="category-tree__group" key={id}>
            <label htmlFor={id}>{category.assemblyGroupName}</label>

            {category.hasChilds && <CategoryFilterResultSubcategory categories={category.children} carId={carId} />}
        </div>
    );
};

export default CategoryFilterResultCategory;
