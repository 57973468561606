import React from 'react';
import classNames from 'classnames';
import Img from 'components/Image/Img';
import { SearchTabType } from 'components/SearchBlock/SearchBlock';

interface IProps {
    tabName: SearchTabType;
    iconName: string;
    label: string;
    active: boolean;
    setCurrentTab: (tab: SearchTabType) => void;
}

const SearchBlockTab = (props: IProps) => {
    const { tabName, iconName, label, active, setCurrentTab } = props;

    return (
        <li key={tabName}>
            <button
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    setCurrentTab(tabName);
                }}
                className={classNames({ active })}
            >
                <div className="holder">
                    <div className="icon">
                        <Img alt={label} iconName={iconName} />
                    </div>
                    <div className="label">{label}</div>
                </div>
            </button>
        </li>
    );
};

export default SearchBlockTab;
