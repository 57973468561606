import * as React from 'react';
import classNames from 'classnames';

import 'automaailm-ui/lib/Applications/Checkout/Components/Banklinks/styles.scss';

interface IBanklinksProps {
    components: React.Component[];
}

/**
 * Basic list element with hover background. You should wrap this inside ListBasic component.
 */
const Banklinks = (props: IBanklinksProps) => {
    if (props.components.length === 0) {
        return null;
    }
    return (
        <ul
            className={classNames('banklinks', {
                'layout-default': !window.detailedPaymentEnabled,
                'layout-vertical': window.detailedPaymentEnabled,
            })}
        >
            {props.components}
        </ul>
    );
};

export default Banklinks;
