import { getHeaders } from '../../helpers/request/getHeaders';
import { QueryConfig, RequestBody } from 'redux-query';

export interface IRequestData {
    type: string;
    url: string;
    data: RequestBody;
}

export const formDataRequest = (data: IRequestData): QueryConfig => {
    const url = window.API_URL;
    let queryString = `?form_key=${window.form_key}`;
    Object.entries(data.data).forEach(([field, value]) => {
        queryString += `&${field}=${value}`;
    });
    return {
        url: `${url}/${data.url}${queryString}`,
        options: {
            method: 'POST',
            headers: getHeaders(),
        },
        transform: (response: any) => ({
            [`${data.type}`]: response as any,
        }),
        update: {
            [`${data.type}`]: (prev: any, next: any) => {
                return next;
            },
        },
    };
};
