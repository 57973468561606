import { request } from 'data/requests/request';

const typeRequest = (location: string, manufacturer: string, model: string) =>
    request({
        type: 'matTypeResponse',
        url: 'mat/search/type',
        notApi: true,
        data: {
            location,
            manufacturer,
            model,
        },
        force: true,
    });

export default typeRequest;
