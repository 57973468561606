import { default as React } from 'react';

const RegisterFormHandler = () => {
    const element = document.querySelector('input[id=is_company]');

    if (!element) {
        return <React.Fragment />;
    }
    element.addEventListener('change', (e: Event) => {
        const isChecked = (e.target as HTMLInputElement)?.checked;
        if (isChecked) {
            document.getElementById('create_address')?.setAttribute('value', '1');
        } else {
            document.getElementById('create_address')?.setAttribute('value', '0');
        }
    });
    return <React.Fragment />;
};

export default RegisterFormHandler;
