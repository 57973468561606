import * as React from 'react';
import Overlay from 'automaailm-ui/lib/Components/Overlay/index';
import { Trans, useTranslation } from 'react-i18next';
import useOverlays from '../../../components/overlay/Overlay';

interface IProps {
    renewTime: any;
    timeRemaining: number;
}

const TimeRunningOut = (props: IProps) => {
    const { t } = useTranslation();
    const { overlays, closeOverlay } = useOverlays();
    const { renewTime, timeRemaining } = props;

    return (
        <React.Fragment>
            <Overlay
                isOpen={overlays.indexOf('expiring') !== -1}
                layout="dialog"
                doClose={(e) => {
                    e.preventDefault();
                }}
                dialogConfig={{
                    description: (
                        <React.Fragment>
                            <Trans i18nKey="checkout.descriptionExtend" values={{ time: timeRemaining }}>
                                <b>5 minutes</b>
                            </Trans>
                            <br />
                            {t('Would you like to extend this time?')}
                        </React.Fragment>
                    ),
                    buttons: [
                        {
                            title: t('checkout.Lengthen'),
                            intent: 'primary',
                            onClick: () => {
                                closeOverlay('expiring');
                                renewTime();
                            },
                        },
                        {
                            title: t('checkout.Close'),
                            intent: 'primary',
                            layout: 'link',
                            onClick: () => {
                                closeOverlay('expiring');
                            },
                        },
                    ],
                }}
            />
        </React.Fragment>
    );
};
export default TimeRunningOut;
