import i18next from 'i18next';
import * as ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import eeTranslations from '../../data/locales/ee/translation.json';
import enTranslations from '../../data/locales/en/translation.json';

const i18n = (translations) => {
    // Combine api based translations with react file translations
    if (!translations['en']) {
        translations['en'] = {};
    }
    if (!translations['et']) {
        translations['et'] = {};
    }
    translations['en']['common'] = { ...enTranslations };
    translations['et']['common'] = { ...eeTranslations };

    i18next
        .use(initReactI18next)
        .use(ICU as any)
        .init({
            fallbackLng: 'en',
            resources: { ...translations },
            ns: ['common', 'checkout'],
            defaultNS: 'common',
            debug: false,
            interpolation: {
                escapeValue: false,
            },
            nsSeparator: '.',
            parseMissingKeyHandler: (key: string) => {
                // todo :: currently strips the missing namespace only
                // if you are debugging, then add a wrapper for <span color="red"> or something similar
                const replacedKey = key.replace(/^.*\./, '');
                return Env.TRANSLATION_KEYS_ENABLED === 'yes' ? key : replacedKey;
            },
        });
    return i18next;
};

export default i18n;
