import React, { useEffect, useState } from 'react';
import { ITire } from 'components/SearchBlock/TireSearch/TireSearchBlock';
import TireDataSelect from 'components/SearchBlock/TireSearch/SelectFields/TireDataSelect';

interface IProps {
    selectedTire?: ITire;
    setSelectedTire: (tire?: ITire) => void;
    placeholder: string;
    options: Array<string>;
}

const HeightSelect = (props: IProps) => {
    const { selectedTire, setSelectedTire, placeholder, options } = props;
    const [height, setHeight] = useState<string>();

    useEffect(() => {
        setSelectedTire({ ...selectedTire, height });
    }, [height]);

    return (
        <TireDataSelect
            placeholder={placeholder}
            options={options}
            attribute={selectedTire?.height}
            setAttribute={setHeight}
        />
    );
};

export default HeightSelect;
