import * as React from 'react';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { useMutation } from 'redux-query-react';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import { useEffect } from 'react';
import Toaster from 'automaailm-ui/lib/Components/Toaster';
import { useTranslation } from 'react-i18next';
import { request } from 'data/requests/request';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes?: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
}

const HolmInstallment = (props: IProps) => {
    const { method } = props;
    const { t: translate } = useTranslation();

    const [{}, redirectRequest] = useMutation(() =>
        request({
            notApi: true,
            method: 'GET',
            type: 'holmBankPayment',
            url: 'holmbank/payment/create',
        }),
    );

    const effect = (event) => {
        if (event.detail?.setRedirectUrl && method.code === event.detail.methodCode) {
            redirectRequest().then((response) => {
                if (response.body.errorMessage) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.errorMessage,
                    });
                }
                if (response.body.redirectUrl) {
                    event.detail.setRedirectUrl({ to: response.body.redirectUrl, absolute: true });
                } else {
                    Toaster.addToast({
                        intent: 'danger',
                        text: translate(
                            'There was an error with holm bank. Please choose other method or contact our support',
                        ),
                    });
                }
            });
        }
    };

    useEffect(() => {
        window.addEventListener('payment-response-receive', effect);
        return function cleanup() {
            window.removeEventListener('payment-response-receive', effect);
        };
    }, []);

    return (
        <SimplePayment
            method={method.code}
            key={method.code}
            onClick={() => {
                props.paymentMethodProps.onClick({ redirect: undefined });
            }}
            label={method.title}
            paymentMethodProps={props.paymentMethodProps}
        />
    );
};

export default HolmInstallment;
