import React, { useEffect, useState } from 'react';
import { IUrls } from 'components/SearchBlock/SearchBlock';
import SearchButton from 'components/SearchBlock/SearchButton';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import productRequest from 'data/matSearch/ProductRequest';

interface IProps {
    buttonLabel: string;
    queryLabel: string;
    urls: IUrls;
    manufacturer: string;
    location: string;
    model?: string;
    type?: string;
}

const mapStateToProps = (state) => {
    return {
        matIds: state.entities.matProductResponse as string[],
    };
};

const MatSearchButton = (props: IProps) => {
    const { urls, queryLabel, buttonLabel, manufacturer, model, type, location } = props;

    const [href, setHref] = useState<string>('');
    const [{ isFinished }] = useRequest(productRequest(location, manufacturer, model, type));
    const { matIds } = useSelector(mapStateToProps);

    useEffect(() => {
        if (isFinished && matIds) {
            const url = new URL(urls.searchResult);
            let searchParams = `${queryLabel} ${manufacturer}`;
            if (model) {
                searchParams += ` ${model}`;
            }
            if (type) {
                searchParams += ` ${type}`;
            }

            url.searchParams.append('q', searchParams);
            matIds.forEach((matId) => {
                url.searchParams.append('matIds[]', matId);
            });

            setHref(url.toString());
        }
    }, [isFinished, matIds]);

    return <SearchButton label={buttonLabel} href={href} />;
};

export default MatSearchButton;
