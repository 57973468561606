import * as React from 'react';
import classNames from 'classnames';
import FieldGroup from 'automaailm-ui/lib/Components/FormListItem/index';

export interface IFormRowProps {
    /** Label to display */
    label?: React.ReactNode;
    /** Error to display */
    error?: React.ReactNode;
    /** Show error icon */
    showErrorIcon?: boolean;
    /** Description text to display */
    description?: React.ReactNode;
    /** Display text value instead of children */
    value?: React.ReactNode;
    /** Mark row as required */
    required?: boolean;
    /** Place asterisk to label */
    requiredPosition?: 'label';
    children?: React.ReactNode;
    /** Path to assets */
    assetBasePath?: string;
    /** Display as fieldgroup */
    isFieldSet?: boolean;
    isCheckboxes?: boolean;
    /** Display separation border */
    isSeparated?: boolean;
    /** Display in columns */
    isSplit?: boolean;
}
/**
 * Single form row for List
 */
export const FormListItem: React.FunctionComponent<IFormRowProps> = (props: IFormRowProps) => {
    const required = <em>*</em>;

    const renderContent = () => {
        return (
            <React.Fragment>
                <div className={props.isFieldSet ? '' : 'form-row-items'}>
                    {props.value !== undefined ? (
                        <div className="form-row-value">{props.value ? props.value : '-'}</div>
                    ) : (
                        props.children
                    )}
                </div>
                {((!!props.error && typeof props.error !== 'boolean') || !!props.description) && (
                    <div className="form-row-instructions">
                        {props.error && (
                            <React.Fragment>
                                {props.showErrorIcon && (
                                    <img src={`${props.assetBasePath ?? 'assets/img/'}/icon_error.svg`} alt="!" />
                                )}
                                <div style={{ color: 'red' }}>{props.error}</div>
                            </React.Fragment>
                        )}
                        {props.description && <div className="form-row-description">{props.description}</div>}
                    </div>
                )}
            </React.Fragment>
        );
    };

    return (
        <li
            className={classNames({
                error: props.error,
                separated: props.isSeparated,
                split: props.isSplit,
            })}
        >
            {props.isFieldSet ? (
                <FieldGroup label={props.label} required={props.required} isCheckboxes={props.isCheckboxes}>
                    {renderContent()}
                </FieldGroup>
            ) : (
                <React.Fragment>
                    {!!props.label && (
                        <label className="form-row-label">
                            {props.label}
                            {props.required && required}
                        </label>
                    )}
                    {props.required && !props.label && required}
                    {renderContent()}
                </React.Fragment>
            )}
        </li>
    );
};
export default FormListItem;
