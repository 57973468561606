import * as React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import FilterSidebar from 'components/Filter/Sidebar';

export interface IFilterProps {
    /* DOM to parse */
    dom: string;
    /** Unsafe config */
    config?: any;
}

const Filter: React.FunctionComponent<IFilterProps> = (props) => {
    // Main output
    return props.config && !!props.config.output ? (
        <HashRouter>
            <Switch>
                <Route exact={true} path="/:level1?/:level2?/:level3?/:level4?/:level5?/">
                    {(props.config &&
                        props.config.output &&
                        {
                            sidebar: <FilterSidebar dom={props.dom} config={props.config} />,
                        }[props.config.output]) ||
                        null}
                </Route>
            </Switch>
        </HashRouter>
    ) : null;
};

Filter.displayName = 'Filter';

export default Filter;
