import React from 'react';
import { Category } from 'components/CategoryList/CategoryList';

interface IProps {
    categories: Array<Category>;
}

const PopularCategoryList = (props: IProps) => {
    const { categories } = props;

    return (
        <ul className="popular-categories">
            {categories.map((category, index) => (
                <li key={category.assemblyGroupNodeId + index}>
                    <a href={category.href}>{category.assemblyGroupName}</a>
                </li>
            ))}
        </ul>
    );
};

export default PopularCategoryList;
