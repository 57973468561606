import React, { useEffect, useState } from 'react';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import { IMat } from '../MatSearchBlock';
import MatDataSelect from 'components/SearchBlock/MatSearch/SelectFields/MatDataSelect';
import modelRequest from 'data/matSearch/ModelRequest';
import { MatOptions } from 'components/SearchBlock/MatSearch/SelectFields/ManufacturerSelect';

interface IProps {
    manufacturer: string;
    location: string;
    placeholder: string;
    selectedMat?: IMat;
    setSelectedMat: (mat?: IMat) => void;
}

const mapStateToProps = (state) => {
    return {
        models: state.entities.matModelResponse as MatOptions[],
    };
};

const ModelSelect = (props: IProps) => {
    const { manufacturer, location, selectedMat, setSelectedMat, placeholder } = props;
    const [model, setSelectedModel] = useState<undefined | string>(undefined);

    useEffect(() => {
        if (!model) {
            setSelectedMat({
                ...selectedMat,
                model: undefined,
                type: undefined,
            });
        } else {
            setSelectedMat({ ...selectedMat, model });
        }
    }, [model]);

    useRequest(modelRequest(manufacturer, location));
    const { models } = useSelector(mapStateToProps);

    return (
        <MatDataSelect placeholder={placeholder} options={models} setAttribute={setSelectedModel} attribute={model} />
    );
};

export default ModelSelect;
