import React, { useEffect, useState } from 'react';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import { IMat } from '../MatSearchBlock';
import MatDataSelect from 'components/SearchBlock/MatSearch/SelectFields/MatDataSelect';
import { MatOptions } from 'components/SearchBlock/MatSearch/SelectFields/ManufacturerSelect';
import typeRequest from 'data/matSearch/TypeRequest';

interface IProps {
    location: string;
    manufacturer: string;
    model: string;
    placeholder: string;
    selectedMat?: IMat;
    setSelectedMat: (mat?: IMat) => void;
}

const mapStateToProps = (state) => {
    return {
        types: state.entities.matTypeResponse as MatOptions[],
    };
};

const TypeSelect = (props: IProps) => {
    const { location, manufacturer, model, selectedMat, setSelectedMat, placeholder } = props;
    const [type, setSelectedType] = useState<undefined | string>(undefined);

    useEffect(() => {
        if (!type) {
            setSelectedMat({
                ...selectedMat,
                type: undefined,
            });
        } else {
            setSelectedMat({ ...selectedMat, type });
        }
    }, [type]);

    useRequest(typeRequest(location, manufacturer, model));
    const { types } = useSelector(mapStateToProps);

    return <MatDataSelect placeholder={placeholder} options={types} setAttribute={setSelectedType} attribute={type} />;
};

export default TypeSelect;
