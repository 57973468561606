import React, { useState } from 'react';
import MatSearchSelect from 'components/SearchBlock/MatSearch/MatSearchSelect';
import { ITranslations, IUrls } from 'components/SearchBlock/SearchBlock';

interface IProps {
    labels: ITranslations;
    urls: IUrls;
}

export interface IMat {
    manufacturer?: string;
    model?: string;
    type?: string;
    location?: string;
}

const MatSearchBlock = (props: IProps) => {
    const [selectedMat, setSelectedMat] = useState<IMat | undefined>(undefined);

    return (
        <MatSearchSelect
            selectedMat={selectedMat}
            setSelectedMat={setSelectedMat}
            labels={props.labels}
            urls={props.urls}
        />
    );
};

export default MatSearchBlock;
