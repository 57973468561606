import * as React from 'react';
import SimplePayment from '../../../../components/checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';
import Toaster from 'automaailm-ui/lib/Components/Toaster/index';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import { PaymentMethodEnum } from '../../../../components/checkout/Payment/Methods/MethodHandler';
import { useEffect } from 'react';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
}

const TfBank = (props: IProps) => {
    const { method } = props;

    const [{}, postData] = useMutation(() =>
        request({ type: 'tfbank', url: `tfbankhp/request/redirect`, notApi: true }),
    );

    const effect = (event) => {
        const methods = [PaymentMethodEnum.tfbank_hp];
        if (event.detail?.setRedirectUrl && methods.includes(event.detail.methodCode)) {
            postData().then((response) => {
                if (response.body.errorMessage) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.errorMessage,
                    });
                }
                if (response.body.redirect_url) {
                    event.detail.setRedirectUrl({ to: response.body.redirect_url, absolute: true });
                }
            });
        }
    };

    useEffect(() => {
        window.addEventListener('payment-response-receive', effect);
        return function cleanup() {
            window.removeEventListener('payment-response-receive', effect);
        };
    });

    return (
        <SimplePayment
            method={method.code}
            key={method.code}
            onClick={() => {
                props.paymentMethodProps.onClick({ redirect: undefined });
            }}
            label={method.title}
            paymentMethodProps={props.paymentMethodProps}
        />
    );
};

export default TfBank;
