import * as React from 'react';
import classNames from 'classnames';
import { getScrollParent, usePrevious } from 'automaailm-ui/lib/Utilities/Generic';
import { scrollIntoView } from 'automaailm-ui/lib/Utilities/ScrollTo';
import { getIconUrl } from '../../../helpers/image/iconUrl';

export interface ISearchResultProps {
    name: string;
    href: string;
    parent?: string;
    active?: boolean;
}

const ResultCategory: React.FunctionComponent<ISearchResultProps> = (props) => {
    const prevActive = usePrevious(props.active);
    const domElem = React.useRef(null);

    React.useEffect(() => {
        if (props.active && !prevActive) {
            const d = (domElem.current as unknown) as HTMLElement;
            if (d) {
                const domElemParent = getScrollParent(d);
                scrollIntoView(d, domElemParent, {});
            }
        }
    }, [props.active, prevActive]);

    return (
        <li className="search__results__result" ref={domElem}>
            <a href={props.href} className={classNames({ active: props.active })}>
                <div className="image" style={{ backgroundImage: `url(${getIconUrl('icon_category')})` }} />
                <div className="text">
                    <div className="name">{props.name}</div>
                    {props.parent && <div className="price">{props.parent}</div>}
                </div>
            </a>
        </li>
    );
};

export default ResultCategory;
