import React from 'react';
import SearchButton from 'components/SearchBlock/SearchButton';
import ManufacturerSelect from 'components/SearchBlock/MatSearch/SelectFields/ManufacturerSelect';
import { IMat } from 'components/SearchBlock/MatSearch/MatSearchBlock';
import DisabledSelect from 'components/SearchBlock/CarSearch/SelectFields/Types/DisabledSelect';
import ModelSelect from 'components/SearchBlock/MatSearch/SelectFields/ModelSelect';
import TypeSelect from 'components/SearchBlock/MatSearch/SelectFields/TypeSelect';
import LocationSelect from 'components/SearchBlock/MatSearch/SelectFields/LocationSelect';
import { ITranslations, IUrls } from 'components/SearchBlock/SearchBlock';
import MatSearchButton from 'components/SearchBlock/MatSearch/MatSearchButton';

export interface MatOptions {
    id: string;
    label: string;
}

export interface IProps {
    selectedMat?: IMat;
    setSelectedMat: (mat?: IMat) => void;
    labels: ITranslations;
    urls: IUrls;
}

const MatSearchSelect = (props: IProps) => {
    const { selectedMat, setSelectedMat, labels, urls } = props;

    return (
        <div className="box-search__group">
            <div className="box-search__label">{labels.selectVehicleForMatsLabel}</div>
            <div className="box-search__fields">
                <LocationSelect
                    selectedMat={selectedMat}
                    setSelectedMat={setSelectedMat}
                    placeholder={labels.location}
                />
                {!selectedMat?.location && (
                    <div className="column">
                        <div className="control-holder">
                            <DisabledSelect placeholder={labels.manufacturer} />
                        </div>
                    </div>
                )}
                {selectedMat?.location && (
                    <ManufacturerSelect
                        location={selectedMat.location}
                        selectedMat={selectedMat}
                        setSelectedMat={setSelectedMat}
                        placeholder={labels.manufacturer}
                    />
                )}
                {!selectedMat?.manufacturer && (
                    <div className="column">
                        <div className="control-holder">
                            <DisabledSelect placeholder={labels.model} />
                        </div>
                    </div>
                )}
                {selectedMat?.manufacturer && selectedMat?.location && (
                    <ModelSelect
                        selectedMat={selectedMat}
                        setSelectedMat={setSelectedMat}
                        manufacturer={selectedMat.manufacturer}
                        location={selectedMat.location}
                        placeholder={labels.model}
                    />
                )}
                {selectedMat?.manufacturer && selectedMat?.model && selectedMat?.location && (
                    <TypeSelect
                        location={selectedMat.location}
                        manufacturer={selectedMat.manufacturer}
                        model={selectedMat.model}
                        selectedMat={selectedMat}
                        setSelectedMat={setSelectedMat}
                        placeholder={labels.type}
                    />
                )}
                {!selectedMat?.model && (
                    <div className="column">
                        <div className="control-holder">
                            <DisabledSelect placeholder={labels.type} />
                        </div>
                    </div>
                )}
                {!selectedMat?.manufacturer && <SearchButton label={labels.find} href={''} disabled={true} />}
                {selectedMat?.manufacturer && selectedMat.location && (
                    <MatSearchButton
                        manufacturer={selectedMat.manufacturer}
                        model={selectedMat.model}
                        type={selectedMat.type}
                        location={selectedMat.location}
                        buttonLabel={labels.find}
                        queryLabel={labels.matsFor}
                        urls={urls}
                    />
                )}
            </div>
        </div>
    );
};

export default MatSearchSelect;
