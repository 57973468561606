import { request } from 'data/requests/request';

const tireLicenceRequest = (licence: string) =>
    request({
        type: 'tireLicenceResponse',
        url: 'carSearch/tireSearch/licencePlate',
        notApi: true,
        data: {
            licence: licence,
        },
    });

export default tireLicenceRequest;
