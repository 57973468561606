import * as React from 'react';
import { useEffect, useState } from 'react';
import { IAttribute } from 'components/Catalog/Interfaces/IAttribute';
import Attribute from 'components/Catalog/Product/Attribute';
import { ISwatch } from 'components/Catalog/Interfaces/ISwatch';
import { IPrice } from 'components/Catalog/Interfaces/IPrice';
import { ISelectedProductInformation } from 'components/Catalog/Product/Detail';
import { IImage } from 'components/Catalog/Interfaces/IImage';

interface IProps {
    images?: IImage[];
}

const Gallery = (props: IProps) => {
    const { images } = props;
    return (
        <div className={'gallery'}>
            {images &&
                images.map((image, key) => (
                    <div
                        key={key}
                        className={`gallery__slide ${image.isMain ? 'active' : ''}`}
                        style={{ backgroundImage: `url(${image.full})` }}
                    />
                ))}
        </div>
    );
};

export default Gallery;
