import * as React from 'react';
import { Assets, Pagination, SortOrder } from 'components/Catalog/Interfaces/List/IListResponse';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { useKeypress } from 'automaailm-ui/lib/Utilities/Generic';
import { useState } from 'react';

interface IProps {
    pagination: Pagination;
    assets: Assets;
    pageNumber: number;
    setPageNumber: (number: number) => void;
}

const Paginator = (props: IProps) => {
    const { pagination, assets, pageNumber, setPageNumber } = props;
    const history = useHistory();
    const location = useLocation();
    const q = parse(location.search);

    const [focused, setFocused] = useState(false);

    const currentPage = pageNumber;
    const maxPages = pagination.lastPage;

    // Navigate with enter

    useKeypress(
        'Enter',
        () => {
            if (focused) {
                let newPageNumber = pageNumber;
                if (newPageNumber > maxPages) {
                    newPageNumber = maxPages;
                }
                if (newPageNumber < 1) {
                    newPageNumber = 1;
                }
                setPageNumber(newPageNumber);
                history.push(location.pathname + '?' + stringify({ ...q, p: newPageNumber }));
            }
        },
        [focused, pageNumber],
    );

    // Main output

    const prevPage: number | null = currentPage > 1 ? currentPage - 1 : 1;
    const nextPage: number | null = currentPage < maxPages ? currentPage + 1 : maxPages;
    return (
        <React.Fragment>
            <div className={'paginator'}>
                <NavLink
                    className="paginator__prev"
                    to={location.pathname + '?' + stringify({ ...q, p: prevPage ? prevPage : undefined })}
                    isActive={() => false}
                >
                    <span>{assets.prevLabel}</span>
                </NavLink>
                <div className="paginator__counter">
                    <input
                        type="text"
                        value={pageNumber}
                        onChange={(e) => {
                            setPageNumber(!e.target.value ? 0 : parseInt(e.target.value as string));
                        }}
                        onFocus={() => {
                            setFocused(true);
                        }}
                        onBlur={() => {
                            setFocused(false);
                        }}
                    />
                    <span className="paginator__label"> / {maxPages}</span>
                </div>
                <NavLink
                    className="paginator__next"
                    to={location.pathname + '?' + stringify({ ...q, p: nextPage ? nextPage : undefined })}
                    isActive={() => false}
                >
                    <span>{assets.nextLabel}</span>
                </NavLink>
            </div>
        </React.Fragment>
    );
};

export default Paginator;
