import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import { ITranslations, IUrls } from 'components/SearchBlock/SearchBlock';
import { openOverlay } from 'data/overlays/overlayHandler';
import SelectCarOverlay from 'components/SearchBlock/CarSearch/SelectCar/SelectCarOverlay';

interface IProps {
    labels: ITranslations;
    urls: IUrls;
    setSelectedVehicle: (vehicle: IVehicle) => void;
    setLicence: (licence: string) => void;
}

const SelectCar = (props: IProps) => {
    const { labels, urls } = props;
    const dispatch = useDispatch();
    const overlaySelector = 'select-my-car';

    const [showModal, setShowModal] = useState<boolean>(false);

    const open = () => {
        dispatch(openOverlay({ name: overlaySelector }));
        setShowModal(true);
    };

    const linkStyle = {
        cursor: 'pointer',
    };

    return (
        <React.Fragment>
            <a style={linkStyle} onClick={() => open()}>
                {labels.selectOwnCar}
            </a>
            {showModal && (
                <SelectCarOverlay
                    overlaySelector={overlaySelector}
                    labels={labels}
                    urls={urls}
                    setSelectedVehicle={props.setSelectedVehicle}
                    setLicence={props.setLicence}
                />
            )}
        </React.Fragment>
    );
};

export default SelectCar;
