import * as React from 'react';
import { useMutation } from 'redux-query-react';
import addressDeleteRequest from 'data/requests/customer/AddressDeleteRequest';
import DeleteBase from 'components/AccountDelete/DeleteBase';
import vehicleDeleteRequest from 'data/requests/customer/VehicleDeleteRequest';

export interface IVehicleProps {
    config: {
        vehicleId: number;
        linkText: string;
        popUpTitle: string;
        popUpButtonConfirm: string;
        popUpButtonCancel: string;
    };
}

const VehicleDelete: React.FunctionComponent<IVehicleProps> = (props) => {
    const [{}, deleteRequest] = useMutation(() => vehicleDeleteRequest(props.config.vehicleId));

    return <DeleteBase deleteRequest={deleteRequest} config={props.config} />;
};

VehicleDelete.displayName = 'Vehicle delete';

export default VehicleDelete;
