import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';

export const SET_SELECTED_VEHICLE = 'setSelectedVehicle';

const selectedVehicleReducer = (
    state: {
        selectedVehicle?: IVehicle;
    } = {
        selectedVehicle: undefined,
    },
    action: {
        type: string;
        payload: any;
    },
) => {
    const handleSet = (payload?: IVehicle) => {
        return {
            ...state,
            selectedVehicle: payload,
        };
    };

    switch (action.type) {
        case SET_SELECTED_VEHICLE: {
            return handleSet(action.payload);
        }

        default: {
            return state;
        }
    }
};

export default selectedVehicleReducer;
