import { Category } from 'components/CategoryList/CategoryList';
import { forEach, includes, lowerCase, reject } from 'lodash';

export class FilterHelper {
    public filterCategories(
        query: string,
        categories: Array<Category>,
        originalParent: Category | undefined = undefined,
        matchingCategories: Array<Category> = [],
    ) {
        categories.forEach((category) => {
            if (!category.parentNodeId) {
                originalParent = category;
            }

            if (category.children) {
                this.filterCategories(query, category.children, originalParent, matchingCategories);
            } else {
                if (
                    FilterHelper.categoryIncludesQuery(category, query) &&
                    originalParent &&
                    !matchingCategories.includes(originalParent)
                ) {
                    matchingCategories.push(originalParent);
                }
            }
        });

        matchingCategories = this.removeInvalidChildCategories(matchingCategories, query);

        return matchingCategories;
    }

    private removeInvalidChildCategories(
        categories: Array<Category>,
        query: string,
        parentCategory: Category | undefined = undefined,
    ) {
        categories.forEach((category) => {
            if (category.children) {
                this.removeInvalidChildCategories(category.children, query, category);
            }

            if (
                parentCategory &&
                parentCategory.children &&
                !category.hasChilds &&
                !FilterHelper.categoryIncludesQuery(category, query)
            ) {
                parentCategory.children = reject(parentCategory.children, category);
                if (!parentCategory.children.length) {
                    parentCategory.hasChilds = false;
                }
            }
        });

        return categories;
    }

    private static categoryIncludesQuery(category: Category, query: string) {
        return lowerCase(category.assemblyGroupName).includes(lowerCase(query));
    }
}
