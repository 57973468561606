import React, { useEffect, useState } from 'react';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import Select from 'components/SearchBlock/CarSearch/SelectFields/Types/Select';
import manufacturerRequest from 'data/carSearch/ManufacturerRequest';
import { IModel } from 'components/SearchBlock/CarSearch/CarSelectSearch';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';

const mapStateToProps = (state) => {
    return {
        models: state.entities.manufacturerResponse as IModel[],
    };
};

interface IProps {
    selectedVehicle?: IVehicle;
    setSelectedVehicle: (vehicle?: IVehicle) => void;
    placeholder: string;
}

const ManufacturerSelect = (props: IProps) => {
    const { selectedVehicle, setSelectedVehicle, placeholder } = props;
    const [manufacturer, setSelectedManufacturer] = useState<IModel>();

    useEffect(() => {
        if (selectedVehicle) {
            setSelectedManufacturer(selectedVehicle.manufacturer);
        }
    }, [selectedVehicle]);

    useEffect(() => {
        if (!manufacturer) {
            setSelectedVehicle({
                manufacturer: undefined,
                model: undefined,
                type: undefined,
            });
        } else {
            setSelectedVehicle({ ...selectedVehicle, manufacturer });
        }
    }, [manufacturer]);

    useRequest(manufacturerRequest());
    const { models } = useSelector(mapStateToProps);

    return (
        <div className="control-holder">
            <Select
                placeholder={placeholder}
                models={models}
                setSelectedModel={setSelectedManufacturer}
                selectedModel={manufacturer}
            />
        </div>
    );
};

export default ManufacturerSelect;
