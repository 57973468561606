import * as React from 'react';
import { ActiveFilter, Assets, Categories, Filter } from 'components/Catalog/Interfaces/List/IListResponse';
import CategoryFilters from 'components/Catalog/Product/ListView/CategoryFilters';

interface IProps {
    filters: Filter[];
    activeFilters: ActiveFilter[];
    assets: Assets;
    categories: Categories;
    isSearchQuery: boolean;
}

const Filters = (props: IProps) => {
    const { filters, assets, categories, isSearchQuery } = props;

    const categoryFilter = filters.find((filter: Filter) => filter.id === 'cat');

    const showAllCategoriesTree = !isSearchQuery || (isSearchQuery && !categoryFilter);

    if (!showAllCategoriesTree) {
        return <React.Fragment />;
    }

    return (
        <div className={'category-tree'}>
            {assets && (
                <a href="#" className="menutoggler">
                    <span className="icon">
                        <img src={assets.spriteFilter} alt="" />
                    </span>
                    <span className="label">{assets.openFilterLabel}</span>
                </a>
            )}
            <CategoryFilters categories={categories} />
        </div>
    );
};

export default Filters;
