import { request } from 'data/requests/request';

const modelRequest = (manufacturer: string, location: string) =>
    request({
        type: 'matModelResponse',
        url: 'mat/search/model',
        notApi: true,
        data: {
            manufacturer,
            location,
        },
        force: true,
    });

export default modelRequest;
