import * as React from 'react';
import { Assets, Categories, CategoryListItem, Filter, Item } from 'components/Catalog/Interfaces/List/IListResponse';
import { useHistory } from 'react-router';

interface IProps {
    categoryFilter: Filter;
    assets: Assets;
}

const CategorySelectFilters = (props: IProps) => {
    const { categoryFilter, assets } = props;
    const history = useHistory();

    return (
        <React.Fragment>
            <div className={'category-tree category-select'}>
                <div className={'category-tree__group'} key={`group-${categoryFilter.id}`}>
                    <input type="checkbox" id={`sidebar-group-${categoryFilter.id}`} />
                    <label htmlFor={`sidebar-group-${categoryFilter.id}`}>{categoryFilter.name}</label>
                    <ul>
                        {categoryFilter.items.map((childCategory: Item) => {
                            return (
                                <li key={childCategory.id}>
                                    <a
                                        href="#"
                                        onClick={() => history.push(childCategory.url)}
                                        className={childCategory.isActive ? 'active' : ''}
                                    >
                                        {childCategory.label} {childCategory.count ? `(${childCategory.count})` : ''}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CategorySelectFilters;
