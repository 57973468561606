import * as React from 'react';
import { Assets, Product } from 'components/Catalog/Interfaces/List/IListResponse';
import Products from 'components/Catalog/Product/ListView/Products';

export interface ICrossSellProps {
    config: {
        assets: Assets;
        crossSellProducts: Product[];
    };
}

const CrossSell = (props: ICrossSellProps) => {
    const { crossSellProducts, assets } = props.config;
    return (
        <React.Fragment>
            <Products
                ignoreListView={true}
                products={crossSellProducts}
                inCartBlock={assets.inCartBlock}
                assets={assets}
            />
        </React.Fragment>
    );
};

CrossSell.displayName = 'CrossSell';

export default CrossSell;
