import ControlSelect from 'automaailm-ui/lib/Components/ControlSelect/index';
import React from 'react';
import { IModel } from 'components/SearchBlock/CarSearch/CarSelectSearch';

interface IProps {
    placeholder: string;
    models: IModel[];
    setSelectedModel: (model?: IModel) => void;
    selectedModel?: IModel;
}

const Select = (props: IProps) => {
    const { placeholder, models, selectedModel, setSelectedModel } = props;

    return (
        <ControlSelect
            value={selectedModel?.id || ''}
            onChange={(e) => {
                setSelectedModel(models.find((model) => model.id == e.target.value));
            }}
            placeholder={placeholder}
        >
            <option value="" />
            {models &&
                models.map((model) => (
                    <option key={model.id} value={model.id}>
                        {model.label}
                    </option>
                ))}
            ;
        </ControlSelect>
    );
};

export default Select;
