import * as React from 'react';
import { useEffect, useState } from 'react';
import { IAttribute } from 'components/Catalog/Interfaces/IAttribute';
import Attribute from 'components/Catalog/Product/Attribute';
import { ISwatch } from 'components/Catalog/Interfaces/ISwatch';
import { IPrice } from 'components/Catalog/Interfaces/IPrice';
import { ISelectedProductInformation } from 'components/Catalog/Product/Detail';
import { IImage } from 'components/Catalog/Interfaces/IImage';

interface IProps {
    config: ISwatchConfig;
    setSelectedProductInformation: (selectedProductInformation: ISelectedProductInformation) => void;
}

export interface ISwatchConfig {
    swatches: ISwatch[][];
    options: {
        attributes: IAttribute[];
        chooseText: string;
        currencyFormat: string;
        images: IImage[];
        index: any;
        optionPrices: IPrice;
        priceFormat: any;
        prices: any;
        productId: any;
    };
}

export interface ISelectedAttributes {
    attributeId: string;
    optionId: string;
}

export interface ISelectedSwatch {
    attributeId: string;
    optionId: string;
    products: string;
}

const Configurator = (props: IProps) => {
    const { config, setSelectedProductInformation } = props;
    const { options, swatches } = config;
    const attributes = options.attributes;
    let secondaryAttributes: IAttribute[] = [];
    let defaultSelectedMainSwatch;

    Object.keys(attributes).filter((attribute) => {
        if (!defaultSelectedMainSwatch) {
            const option = attributes[attribute].options.find((option) => option);
            defaultSelectedMainSwatch = {
                attributeId: attribute,
                optionId: option.id,
                products: option.products,
            };
        }
        secondaryAttributes.push(attributes[attribute]);
    });
    const [defaultSelectedMainSwatchStated, setDefaultSelectedMainSwatch] = useState<ISelectedSwatch>(
        defaultSelectedMainSwatch,
    );
    secondaryAttributes = secondaryAttributes.map((secondarySwatch) => {
        if (secondarySwatch.id === defaultSelectedMainSwatchStated?.attributeId) {
            return secondarySwatch;
        }
        const swatchOptions = secondarySwatch.options.filter(
            (swatchOption) =>
                swatchOption.products.filter((value) => defaultSelectedMainSwatchStated?.products.includes(value))
                    .length > 0,
        );
        return { ...secondarySwatch, ...{ options: swatchOptions } };
    });
    const selectedDefaults = secondaryAttributes.map((filteredAttribute) => {
        return {
            attributeId: filteredAttribute.id,
            optionId: filteredAttribute.options.find((option) => option)?.id,
        };
    });
    // @ts-ignore
    const [selectedAttributes, selectAttributes] = useState<ISelectedAttributes[]>(selectedDefaults);

    const ensureAvailableAttributesAreSelected = () => {
        selectedAttributes.forEach((selectedAttribute: ISelectedAttributes, key: number) => {
            secondaryAttributes.forEach((attribute) => {
                if (attribute.id === selectedAttribute.attributeId) {
                    let hasOption = false;
                    Object.values(attribute.options).forEach((attributeOption) => {
                        if (hasOption) {
                            return;
                        }

                        if (attributeOption.id === selectedAttribute.optionId) {
                            hasOption = true;
                        }
                    });
                    // assign first available option in case it is not available in this configuration
                    if (!hasOption && attribute.options.length > 0) {
                        selectedAttributes[key].optionId = attribute.options[0].id;
                        selectAttributes(selectedAttributes);
                    }
                }
            });
        });
    };

    const getSelectedProducts = () => {
        let products: string[] | undefined;

        selectedAttributes.forEach((selectedAttribute: ISelectedAttributes) => {
            Object.values(options.attributes).forEach((attribute) => {
                if (attribute.id === selectedAttribute.attributeId) {
                    Object.values(attribute.options).forEach((attributeOption) => {
                        if (attributeOption.id === selectedAttribute.optionId) {
                            if (!products) {
                                products = attributeOption.products;
                            } else {
                                products = products.filter((value) => attributeOption.products.includes(value));
                            }
                        }
                    });
                }
            });
        });
        return products;
    };

    useEffect(() => {
        let products: string[] | undefined;

        if (options.attributes) {
            ensureAvailableAttributesAreSelected();
            products = getSelectedProducts();
        }

        if (products?.length) {
            const selectedProductId = products[0];
            const images = options.images[selectedProductId];
            const prices = options.optionPrices[selectedProductId];

            setSelectedProductInformation({
                isAvailable: true,
                productId: selectedProductId,
                images,
                prices,
                selectedAttributes,
            });
        } else {
            setSelectedProductInformation({ isAvailable: false });
        }
    }, [selectedAttributes]);

    return (
        <React.Fragment>
            {attributes &&
                secondaryAttributes.map((secondarySwatch: IAttribute) => (
                    <Attribute
                        key={secondarySwatch.id}
                        swatches={swatches}
                        attribute={secondarySwatch}
                        selectedAttributes={selectedAttributes}
                        selectAttributes={selectAttributes}
                        setDefaultSelectedMainSwatch={setDefaultSelectedMainSwatch}
                        defaultSelectedMainSwatch={defaultSelectedMainSwatch}
                    />
                ))}
        </React.Fragment>
    );
};

export default Configurator;
