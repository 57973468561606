import { request } from 'data/requests/request';

const typeRequest = (manuId: string, modelId: string) =>
    request({
        type: 'typeResponse',
        url: 'carSearch/search/engine',
        notApi: true,
        data: {
            manuId: manuId,
            modId: modelId,
        },
        force: true,
    });

export default typeRequest;
