import { Dispatch } from 'redux';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import { SET_SELECTED_VEHICLE } from 'data/selectedVehicle/selectedVehicleReducer';

export function setSelectedVehicleInStore(payload?: IVehicle) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: SET_SELECTED_VEHICLE,
            payload,
        });
    };
}
