import { request } from 'data/requests/request';

const locationRequest = () =>
    request({
        type: 'matLocationResponse',
        url: 'mat/search/location',
        notApi: true,
        force: true,
    });

export default locationRequest;
