import * as React from 'react';
import { Assets, Product } from 'components/Catalog/Interfaces/List/IListResponse';
import { useMutation } from 'redux-query-react';
import quoteIdRequest from 'data/requests/product/quoteIdRequest';
import toCartRequest from 'data/requests/product/toCartRequest';
import { useCallback, useState } from 'react';
import formProductRequest, { IProductRequest } from 'data/requests/product/data/formProductRequest';
import Toaster from 'automaailm-ui/lib/Components/Toaster/index';

interface IProps {
    product: Product;
    assets?: Assets;
}

const ProductActions = (props: IProps) => {
    const { product, assets } = props;
    const [{}, quoteRequest] = useMutation(() => quoteIdRequest());
    const [{}, addToCartRequest] = useMutation((data, quoteIdMask: string) => toCartRequest(data, quoteIdMask));

    const addToCart = useCallback(async () => {
        const quoteIdResponse = await quoteRequest();
        if (quoteIdResponse.status !== 200) {
            // Todo maybe a message?
            window.location.reload();
        }
        const { quoteId } = quoteIdResponse.body;

        const productRequestData: IProductRequest = formProductRequest(quoteId, product.sku, product.type, []);
        const response = await addToCartRequest(productRequestData, quoteId);
        if (response.status === 200) {
            window.dispatchEvent(
                new CustomEvent('cart-altered', {
                    detail: {
                        productId: product.id,
                        name: product.name,
                        image: product.image.image_url,
                        sku: product.sku,
                    },
                }),
            );
            if (assets?.inCartBlock) {
                Toaster.addToast({
                    intent: 'success',
                    text: assets.inCartBlock,
                    asHtml: true,
                });
            }
        } else {
            if (response?.body?.message) {
                Toaster.addToast({
                    intent: 'danger',
                    text: response?.body?.message,
                    asHtml: true,
                });
            }
        }
    }, [addToCartRequest, quoteRequest]);
    return (
        <React.Fragment>
            {product && product.isSalable && (
                <button onClick={addToCart} className="box-product__prices__action">
                    <div className="holder">
                        <img src={`${window.assetUrl}/assets/img/icon_cart.svg`} alt="" />
                        <span className="label">{props.assets?.inCartLabel}</span>
                    </div>
                </button>
            )}
        </React.Fragment>
    );
};

export default ProductActions;
