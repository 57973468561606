import React, { useEffect, useState } from 'react';
import { ITire } from 'components/SearchBlock/TireSearch/TireSearchBlock';
import TireDataSelect from 'components/SearchBlock/TireSearch/SelectFields/TireDataSelect';

interface IProps {
    selectedTire?: ITire;
    setSelectedTire: (tire?: ITire) => void;
    placeholder: string;
    options: Array<string>;
}

const SeasonSelect = (props: IProps) => {
    const { selectedTire, setSelectedTire, placeholder, options } = props;
    const [season, setSeason] = useState<string>();

    useEffect(() => {
        setSelectedTire({ ...selectedTire, season });
    }, [season]);

    return (
        <TireDataSelect
            placeholder={placeholder}
            options={options}
            attribute={selectedTire?.season}
            setAttribute={setSeason}
        />
    );
};

export default SeasonSelect;
