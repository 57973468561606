import { request } from 'data/requests/request';

const licenceRequest = (licence: string) =>
    request({
        type: 'licenceResponse',
        url: 'carSearch/search/licencePlate',
        notApi: true,
        data: {
            licence: licence,
        },
    });

export default licenceRequest;
