import { request } from 'data/requests/request';
import * as queryString from 'querystring';

const tecDocListRequest = (url, data, type: string = 'productList') => {
    const query = queryString.stringify(data);

    return request({ type, url: `${url}?${query}`, notApi: true, force: true });
};

export default tecDocListRequest;
