import React, { useEffect, useState } from 'react';
import SearchBlockTab from 'components/SearchBlock/SearchBlockTab';
import CarSearchBlock, { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import TireSearchBlock from 'components/SearchBlock/TireSearch/TireSearchBlock';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedVehicleInStore } from 'data/selectedVehicle/selectedVehicleHandler';
import SearchBlockTypeHeader from 'components/SearchBlock/SearchBlockTypeHeader';
import BatterySearchBlock from 'components/SearchBlock/BatterySearch/BatterySearchBlock';
import MatSearchBlock from 'components/SearchBlock/MatSearch/MatSearchBlock';

export interface ITranslations {
    registrationNumber: string;
    insertRegNrOr: string;
    selectOwnCar: string;
    selectOwnCarModal: string;
    selectButtonLabel: string;
    noVehiclesLabel: string;
    insertReg: string;
    searchByVehicle: string;
    find: string;
    add: string;
    manufacturer: string;
    model: string;
    motor: string;
    variationsFound: string;
    placeholderEst: string;
    addNewVehicle: string;
    sparePartsSearch: string;
    batterySearch: string;
    tireSearch: string;
    keywordSearch: string;
    licencePlatePlaceholder: string;
    tireSizes: string;
    season: string;
    summerTires: string;
    allWeatherTires: string;
    winterTires: string;
    tireSizesFound: string;
    findTiresByDimensions: string;
    tireWidth: string;
    tireHeight: string;
    rimDiameter: string;
    tireTooltipWidth: string;
    tireTooltipHeight: string;
    tireTooltipDiameter: string;
    genericErrorMessage: string;
    noCarsFound: string;
    manufacturerPrefillLabel: string;
    selectVehicleForMatsLabel: string;
    matSearch: string;
    type: string;
    location: string;
    matsFor: string;
}

export interface IUrls {
    categoryList: string;
    tireList: string;
    batteryList: string;
    accountUrl: string;
    searchResult: string;
}

export enum SearchTabType {
    CAR_MODEL_SEARCH = 'car_model_search',
    BATTERY_SEARCH = 'battery_search',
    TIRE_SEARCH = 'tire_search',
    KEYWORD_SEARCH = 'keyword_search',
    MAT_SEARCH = 'mat_search',
}

export enum viewTypeEnum {
    VIEW_TYPE_SEARCH = 'VIEW_TYPE_SEARCH',
    VIEW_TYPE_ADD = 'VIEW_TYPE_ADD',
}

interface IProps {
    config: {
        viewType: string;
        labels: ITranslations;
        urls: IUrls;
    };
}
const mapStateToProps = (state) => {
    return state.selectedVehicleReducer.selectedVehicle as IVehicle;
};

const SearchBlock = (props: IProps) => {
    const { viewType, labels, urls } = props.config;
    const [currentTab, setCurrentTab] = useState<SearchTabType | undefined>(undefined);
    const [selectedVehicle, setSelectedVehicle] = useState<IVehicle | undefined>(useSelector(mapStateToProps));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSelectedVehicleInStore(selectedVehicle));
    }, [selectedVehicle]);

    return (
        <div className="box-search">
            {viewType === viewTypeEnum.VIEW_TYPE_SEARCH && (
                <ul className="box-search__tabs">
                    <SearchBlockTab
                        active={currentTab === SearchTabType.CAR_MODEL_SEARCH || currentTab === undefined}
                        iconName={'icon_part'}
                        label={labels.sparePartsSearch}
                        tabName={SearchTabType.CAR_MODEL_SEARCH}
                        setCurrentTab={setCurrentTab}
                    />
                    {urls.batteryList && (
                        <SearchBlockTab
                            active={currentTab === SearchTabType.BATTERY_SEARCH}
                            iconName={'icon_battery'}
                            label={labels.batterySearch}
                            tabName={SearchTabType.BATTERY_SEARCH}
                            setCurrentTab={setCurrentTab}
                        />
                    )}
                    {urls.tireList && (
                        <SearchBlockTab
                            active={currentTab === SearchTabType.TIRE_SEARCH}
                            iconName={'icon_tire'}
                            label={labels.tireSearch}
                            tabName={SearchTabType.TIRE_SEARCH}
                            setCurrentTab={setCurrentTab}
                        />
                    )}
                    <SearchBlockTab
                        active={currentTab === SearchTabType.MAT_SEARCH}
                        iconName={'icon_mat'}
                        label={labels.matSearch}
                        tabName={SearchTabType.MAT_SEARCH}
                        setCurrentTab={setCurrentTab}
                    />
                </ul>
            )}
            <div className="box-search__content">
                <SearchBlockTypeHeader
                    tabType={SearchTabType.CAR_MODEL_SEARCH}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    label={viewType === viewTypeEnum.VIEW_TYPE_SEARCH ? labels.sparePartsSearch : labels.addNewVehicle}
                    iconName={'icon_part'}
                />
                {(currentTab === SearchTabType.CAR_MODEL_SEARCH || currentTab === undefined) && (
                    <CarSearchBlock
                        urls={urls}
                        labels={labels}
                        viewType={viewType}
                        setSelectedVehicle={setSelectedVehicle}
                        selectedVehicle={selectedVehicle}
                    />
                )}
                {urls.tireList && viewType === viewTypeEnum.VIEW_TYPE_SEARCH && (
                    <SearchBlockTypeHeader
                        tabType={SearchTabType.TIRE_SEARCH}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        label={labels.tireSearch}
                        iconName={'icon_tire'}
                    />
                )}
                {urls.tireList && currentTab === SearchTabType.TIRE_SEARCH && (
                    <TireSearchBlock
                        urls={urls}
                        labels={labels}
                        setSelectedVehicle={setSelectedVehicle}
                        selectedVehicle={selectedVehicle}
                    />
                )}
                {viewType === viewTypeEnum.VIEW_TYPE_SEARCH && urls.batteryList && (
                    <SearchBlockTypeHeader
                        tabType={SearchTabType.BATTERY_SEARCH}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        label={labels.batterySearch}
                        iconName={'icon_battery'}
                    />
                )}
                {viewType === viewTypeEnum.VIEW_TYPE_SEARCH &&
                    urls.batteryList &&
                    currentTab === SearchTabType.BATTERY_SEARCH && (
                        <BatterySearchBlock
                            urls={urls}
                            viewType={viewType}
                            labels={labels}
                            setSelectedVehicle={setSelectedVehicle}
                            selectedVehicle={selectedVehicle}
                        />
                    )}
                {viewType === viewTypeEnum.VIEW_TYPE_SEARCH && (
                    <SearchBlockTypeHeader
                        tabType={SearchTabType.MAT_SEARCH}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        label={labels.matSearch}
                        iconName={'icon_mat'}
                    />
                )}
                {viewType === viewTypeEnum.VIEW_TYPE_SEARCH && currentTab === SearchTabType.MAT_SEARCH && (
                    <MatSearchBlock urls={urls} labels={labels} />
                )}
            </div>
        </div>
    );
};

export default SearchBlock;
