import React, { useEffect, useState } from 'react';
import MatDataSelect from 'components/SearchBlock/MatSearch/SelectFields/MatDataSelect';
import { useRequest } from 'redux-query-react';
import manufacturerRequest from 'data/matSearch/ManufacturerRequest';
import { useSelector } from 'react-redux';
import { IMat } from 'components/SearchBlock/MatSearch/MatSearchBlock';

interface IProps {
    location: string;
    selectedMat?: IMat;
    setSelectedMat: (mat?: IMat) => void;
    placeholder: string;
}

export interface MatOptions {
    id: string;
    label: string;
}

const mapStateToProps = (state) => {
    return {
        manufacturers: state.entities.matManufacturerResponse as MatOptions[],
    };
};

const ManufacturerSearchSelect = (props: IProps) => {
    const { location, selectedMat, setSelectedMat, placeholder } = props;

    const [manufacturer, setManufacturer] = useState<string>();

    useEffect(() => {
        if (!manufacturer) {
            setSelectedMat({
                ...selectedMat,
                manufacturer: undefined,
                model: undefined,
                type: undefined,
            });
        } else {
            setSelectedMat({ ...selectedMat, manufacturer });
        }
    }, [manufacturer]);

    useRequest(manufacturerRequest(location));
    const { manufacturers } = useSelector(mapStateToProps);

    return (
        <MatDataSelect
            options={manufacturers}
            placeholder={placeholder}
            attribute={manufacturer}
            setAttribute={setManufacturer}
        />
    );
};

export default ManufacturerSearchSelect;
