import * as React from 'react';
import { useEffect, useState } from 'react';

interface ICrumb {
    label: string;
    title?: string;
    link: string;
    first?: boolean;
    last?: boolean;
    readonly?: boolean;
}

interface ICategoryCrumb {
    id: string;
    name: string;
    url: string;
}
interface IProps {
    config: {
        crumbs: {};
        backUrl?: string;
        backLabel: string;
    };
}

export const Breadcrumbs = (props: IProps) => {
    const [crumbs, setCrumbs] = useState(props.config.crumbs);

    const effect = (e: CustomEvent) => {
        if (e.detail && e.detail.pageBreadCrumbs) {
            const newCrumbs = {};
            Object.keys(crumbs).map((key: string) => {
                const crumb: ICrumb = crumbs[key];
                if (crumb.first) {
                    newCrumbs[key] = crumb;
                }
            });

            e.detail.pageBreadCrumbs.forEach((item: ICategoryCrumb, index: number) => {
                newCrumbs[`z${item.id}`] = {
                    label: item.name,
                    link: e.detail.pageBreadCrumbs.length - 1 !== index ? item.url : null,
                    last: e.detail.pageBreadCrumbs.length - 1 === index,
                };
            });

            setCrumbs(newCrumbs);
        }
    };

    useEffect(() => {
        window.addEventListener('page-changed', (e: Event) => {
            const event = e as CustomEvent;
            if (event.detail.pageBreadCrumbs.length > 0) {
                effect(event);
            }
        });
    }, []);

    return (
        <React.Fragment>
            <ul className="path">
                {Object.keys(crumbs).map((key: string) => {
                    const crumb: ICrumb = crumbs[key];
                    return (
                        <li key={key} className={`item ${key}`}>
                            {crumb.link && (
                                <a href={crumb.link} title={crumb.title}>
                                    {crumb.label}
                                </a>
                            )}
                            {!crumb.link && crumb.last && <strong>{crumb.label}</strong>}
                            {!crumb.link && !crumb.last && crumb.label}
                        </li>
                    );
                })}
            </ul>
            {props.config.backUrl && (
                <a href={props.config.backUrl} className="back">
                    {props.config.backLabel}
                </a>
            )}
        </React.Fragment>
    );
};
