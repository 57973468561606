import React from 'react';
import Block from 'automaailm-ui/lib/Components/Block';
import Overlay from 'automaailm-ui/lib/Components/Overlay/index';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../../../helpers/rootReducer';
import { includes } from 'lodash';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import { ITranslations } from 'components/SearchBlock/SearchBlock';
import OverlayDetails from 'components/SearchBlock/CarSearch/LicencePlate/OverlayDetails';

interface IProps {
    hideResults: () => void;
    overlaySelector: string;
    licence: string;
    setSelectedVehicle: (vehicle: IVehicle) => void;
    cars?: Array<any>;
    labels: ITranslations;
}

const LicenceOverlay = (props: IProps) => {
    const { overlaySelector, cars, setSelectedVehicle, licence, hideResults, labels } = props;
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    return (
        <Overlay
            isOpen={includes(openOverlays, overlaySelector)}
            doClose={hideResults}
            layout="focusview"
            buttonIcons={[
                {
                    icon: 'close',
                    intent: 'link',
                    onClick: (e) => {
                        e.preventDefault();
                        hideResults();
                    },
                },
            ]}
            size="wide"
            title={cars && Object.keys(cars).length + ' ' + labels.variationsFound}
        >
            {cars && (
                <Block>
                    {Object.keys(cars).map((title) => {
                        return (
                            <OverlayDetails
                                labels={labels}
                                licence={licence}
                                setSelectedVehicle={setSelectedVehicle}
                                key={title}
                                title={title}
                                cars={cars[title]}
                                hideResults={hideResults}
                            />
                        );
                    })}
                </Block>
            )}
        </Overlay>
    );
};

export default LicenceOverlay;
