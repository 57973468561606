import React, { useCallback, useState } from 'react';
import Icon from 'automaailm-ui/lib/Components/Icon';
import { useMutation } from 'redux-query-react';
import licenceRequest from 'data/carSearch/LicenceRequest';
import Button from 'automaailm-ui/lib/Components/Button/index';
import { useDispatch, useSelector } from 'react-redux';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { IVehicle } from 'components/SearchBlock/CarSearch/CarSearchBlock';
import { ITranslations, IUrls, viewTypeEnum } from 'components/SearchBlock/SearchBlock';
import LicencePlateInput from 'components/SearchBlock/CarSearch/LicencePlate/LicencePlateInput';
import LicenceOverlay from 'components/SearchBlock/CarSearch/LicencePlate/LicenceOverlay';
import Toaster from 'automaailm-ui/lib/Components/Toaster/index';
import { IModel } from 'components/SearchBlock/CarSearch/CarSelectSearch';
import SelectCar from 'components/SearchBlock/CarSearch/SelectCar/SelectCar';

interface IProps {
    selectedVehicle?: IVehicle;
    setSelectedVehicle: (vehicle: IVehicle) => void;
    iconName: string;
    viewType: string;
    labels: ITranslations;
    urls: IUrls;
}

const mapStateToProps = (state) => {
    return {
        manufacturerModels: state.entities.manufacturerResponse as IModel[],
    };
};

const LicencePlateSearch = (props: IProps) => {
    const { iconName, viewType, labels, selectedVehicle, setSelectedVehicle, urls } = props;
    const [licence, setLicence] = useState(selectedVehicle?.licence ? selectedVehicle.licence : '');
    const [carsFound, setCarsFound] = useState();
    const [carCount, setCarCount] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const { manufacturerModels } = useSelector(mapStateToProps);
    const [{ isPending }, queryLicence] = useMutation((licence: string) => licenceRequest(licence));

    const dispatch = useDispatch();
    const overlaySelector = 'select-car';

    const hideResults = () => {
        dispatch(closeOverlay({ name: 'all' }));
        setModalOpen(false);
    };

    const showResults = () => {
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: overlaySelector }));
        setModalOpen(true);
    };

    const findManufacturerByName = (manufacturerName: string) => {
        const manufacturer = manufacturerModels.find((manufacturer) => manufacturer.label === manufacturerName);

        if (!manufacturer) {
            Toaster.addToast({
                intent: 'danger',
                text: labels.noCarsFound,
                asHtml: true,
            });
        } else {
            Toaster.addToast({
                intent: 'success',
                text: labels.manufacturerPrefillLabel,
                asHtml: true,
            });
        }

        return {
            manuId: manufacturer?.id,
            manuName: manufacturer?.label,
        };
    };

    const getCars = useCallback(
        async (licence) => {
            const response = await queryLicence(licence);
            if (response.status === 200) {
                const vehicleTitles = Object.keys(response.body);
                if (vehicleTitles.length === 1) {
                    let vehicleData = response.body[vehicleTitles[0]].shift();

                    if (!vehicleData.manuId && vehicleData.manuName) {
                        vehicleData = findManufacturerByName(vehicleData.manuName);
                    }

                    setSelectedVehicle({
                        manufacturer: {
                            id: vehicleData.manuId,
                            label: vehicleData.manuName,
                        },
                        model: {
                            id: vehicleData.modId,
                            label: vehicleData.modelName,
                        },
                        type: {
                            id: vehicleData.carId,
                            label: vehicleData.typeTitle,
                        },
                        licence,
                    });
                } else {
                    setCarsFound(response.body);
                    showResults();
                }

                setCarCount(vehicleTitles.length);
            } else {
                Toaster.addToast({
                    intent: 'danger',
                    text: response.body.message,
                    asHtml: true,
                });
            }
        },
        [manufacturerModels],
    );

    const loadCars = async () => {
        await getCars(licence);
    };

    return (
        <div className="box-search__group license-search">
            <div className="box-search__label">
                {viewType === viewTypeEnum.VIEW_TYPE_SEARCH && (
                    <React.Fragment>
                        {labels.insertRegNrOr}&nbsp;
                        <SelectCar
                            urls={urls}
                            setLicence={setLicence}
                            setSelectedVehicle={setSelectedVehicle}
                            labels={labels}
                        />
                    </React.Fragment>
                )}
                {viewType === viewTypeEnum.VIEW_TYPE_ADD && labels.insertReg}
            </div>
            <div className="box-search__fields">
                <div className="column license">
                    <LicencePlateInput
                        licence={licence}
                        iconName={iconName}
                        label={labels.placeholderEst}
                        setLicence={setLicence}
                        placeholder={labels.licencePlatePlaceholder}
                        onEnterEvent={loadCars}
                    />
                    {carCount > 1 && (
                        <div className="box-search__license__results">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    showResults();
                                }}
                            >
                                <span className="label">{carCount + ' ' + labels.variationsFound}</span>
                                <Icon kind={modalOpen ? 'arrow1-up' : 'arrow1-down'} width={10} height={10} />
                            </a>
                        </div>
                    )}
                </div>
            </div>
            <div className="column action">
                <Button
                    disabled={!licence}
                    title={labels.find}
                    intent="primary"
                    loading={isPending}
                    onClick={loadCars}
                />
            </div>
            <LicenceOverlay
                labels={labels}
                setSelectedVehicle={setSelectedVehicle}
                licence={licence}
                cars={carsFound}
                hideResults={hideResults}
                overlaySelector={overlaySelector}
            />
        </div>
    );
};

export default LicencePlateSearch;
