import { combineReducers } from 'redux';
import { entitiesReducer, queriesReducer } from 'redux-query';
import { OverlaysState } from 'data/overlays/interfaces';
import overlaysReducer from 'data/overlays/overlaysReducer';
import selectedVehicleReducer from 'data/selectedVehicle/selectedVehicleReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export interface IStoreState {
    overlaysReducer: OverlaysState;
}

const selectedVehicleConf = {
    key: 'root',
    storage: storage,
    whitelist: ['selectedVehicle'],
};

const combineRootReducer = combineReducers({
    entities: entitiesReducer,
    queries: queriesReducer,
    overlaysReducer,
    selectedVehicleReducer: persistReducer(selectedVehicleConf, selectedVehicleReducer),
});

export default combineRootReducer;
