import { request } from 'data/requests/request';

const manufacturerRequest = () =>
    request({
        type: 'manufacturerResponse',
        url: 'carSearch/search/manufacturer',
        notApi: true,
        force: true,
    });

export default manufacturerRequest;
