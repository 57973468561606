import React, { useState } from 'react';
import PopularCategoryList from 'components/CategoryList/PopularCategoryList';
import CategoryFilter from 'components/CategoryList/CategoryFilter';
import CategoryTree from 'components/CategoryList/CategoryTree';
import CategoryFilterResult from 'components/CategoryList/CategoryFilterResult';
import Category from 'components/CategoryList/CategoryFilterResult';
import 'automaailm-ui/lib/Components/CategorySelect/styles.scss';

export interface ITranslations {
    chooseSparePartCategory: string;
    searchCategory: string;
    noCategoriesFound: string;
    tryAnotherKeyword: string;
}

export interface Category {
    assemblyGroupName: string;
    assemblyGroupNodeId: string;
    parentNodeId?: string;
    href?: string;
    hasChilds: boolean;
    children?: Array<Category>;
}

interface IProps {
    config: {
        labels: ITranslations;
        carId: string;
        categories: Array<Category>;
        popularCategories: Array<Category>;
    };
}

const CategoryList = (props: IProps) => {
    const { labels, categories, popularCategories, carId } = props.config;
    const [query, setQuery] = useState('');

    return (
        <div className="frame-automaailm__body gray">
            <div className="frame-automaailm__body__limiter">
                <div className="select-category">
                    <div className="category-select">
                        <CategoryFilter query={query} setQuery={setQuery} labels={labels} />
                        {query.length < 3 && <PopularCategoryList categories={popularCategories} />}
                        {query.length < 3 && <CategoryTree categories={categories} labels={labels} carId={carId} />}
                        {query.length >= 3 && (
                            <CategoryFilterResult carId={carId} categories={categories} query={query} labels={labels} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryList;
