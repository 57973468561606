import { request } from 'data/requests/request';

export interface IBreadCrumbItem {
    label: string;
    url?: string;
}

const breadcrumbsRequest = (id: string, url: string) =>
    request({
        type: 'breadcrumbsResponse',
        url: `${url}productId/${id}`,
        method: 'GET',
        notApi: true,
        absolute: true,
    });

export default breadcrumbsRequest;
