import * as React from 'react';
import { CategoryListItem } from 'components/Catalog/Interfaces/List/IListResponse';
import { ITranslations } from 'components/Catalog/Product/ListViewWrapper';
import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Buttons from 'automaailm-ui/lib/Components/Buttons';
import FormList from 'automaailm-ui/lib/Components/FormList/index';
import Button from 'automaailm-ui/lib/Components/Button/index';
import FormKey from 'components/GeneralForm/Field/FormKey';
import { getTelephoneRegex } from '../../../helpers/regex/Regex';
import ControlInputWithoutClass from 'components/Form/Input/ControlInputWithoutClass';
import FormListItem from 'components/Form/List/FormListItem';
import ControlTextAreaWithoutClass from 'components/Form/Input/ControlTextAreaWithoutClass';
import { getCaptchaToken } from 'components/GoogleRecaptcha/Index';
import PlainCheckboxWithLabel from 'components/Form/Input/PlainCheckboxWithLabel';

interface IProps {
    activeCategory: CategoryListItem;
    actionUrl: string;
    labels: ITranslations;
}

const OrderSparePart = (props: IProps) => {
    const { activeCategory, labels, actionUrl } = props;
    const [captchaToken, setCaptchaToken] = useState('');
    const formElement = useRef<HTMLFormElement>(null);

    const FormDataValidationSchema = Yup.object().shape({
        name: Yup.string().required(labels.requiredField),
        email: Yup.string().email(labels.invalidEmail).required(labels.requiredField),
        telephone: Yup.string().matches(getTelephoneRegex(), labels.invalidTelephone).required(labels.requiredField),
        carLicenceNumber: Yup.string().required(labels.requiredField),
        inquiry: Yup.string().required(labels.requiredField),
        sendCopyToClient: Yup.boolean().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            telephone: '',
            carLicenceNumber: '',
            inquiry: '',
            sendCopyToClient: false,
        },
        validationSchema: FormDataValidationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: () => {
            void getCaptchaToken().then((token) => {
                setCaptchaToken(token);

                if (formElement.current) {
                    formElement.current.submit();
                }
            });
        },
    });

    return (
        <ul className="list-accordions">
            <li className="list-accordions__item">
                <div className="list-accordions__item__content">
                    <form ref={formElement} action={actionUrl} method="post" onSubmit={formik.handleSubmit}>
                        <FormKey />
                        <input type="hidden" name="g-recaptcha-response" value={captchaToken} />
                        <h2>{labels.sendInquiry}</h2>
                        <p>
                            {activeCategory.name} - {labels.outOfStock}
                        </p>
                        <FormList>
                            <FormListItem label={labels.yourName} required={true} error={formik.errors.name}>
                                <ControlInputWithoutClass
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                />
                            </FormListItem>
                            <FormListItem label={labels.yourEmail} error={formik.errors.email} required={true}>
                                <ControlInputWithoutClass
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                />
                            </FormListItem>
                            <FormListItem
                                label={labels.yourTelephoneNumber}
                                required={true}
                                error={formik.errors.telephone}
                            >
                                <ControlInputWithoutClass
                                    name="telephone"
                                    value={formik.values.telephone}
                                    onChange={formik.handleChange}
                                />
                            </FormListItem>
                            <FormListItem
                                label={labels.carLicenceNumber}
                                required={true}
                                error={formik.errors.carLicenceNumber}
                            >
                                <ControlInputWithoutClass
                                    name="carLicenceNumber"
                                    value={formik.values.carLicenceNumber}
                                    onChange={formik.handleChange}
                                />
                            </FormListItem>
                            <FormListItem label={labels.yourInquiry} required={true} error={formik.errors.inquiry}>
                                <ControlTextAreaWithoutClass
                                    name="inquiry"
                                    value={formik.values.inquiry}
                                    onChange={formik.handleChange}
                                    minRows={5}
                                />
                            </FormListItem>
                            <FormListItem>
                                <PlainCheckboxWithLabel
                                    label={labels.sendCopyToMyAddress}
                                    checked={formik.values.sendCopyToClient}
                                    onChange={() => {
                                        formik.setFieldValue('sendCopyToClient', !formik.values.sendCopyToClient);
                                    }}
                                />
                                {formik.values.sendCopyToClient && (
                                    <input type="hidden" name="sendCopyToClient" value="1" />
                                )}
                            </FormListItem>
                        </FormList>
                        <Buttons>
                            <Button type="submit" intent="primary" title={labels.send} />
                        </Buttons>
                    </form>
                </div>
            </li>
        </ul>
    );
};

export default OrderSparePart;
