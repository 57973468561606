import { request } from 'data/requests/request';

const requiredProductRequest = (productId: string) =>
    request({
        type: 'requiredProductRequest',
        url: `requiredProduct/requiredProduct/index?productId=${productId}`,
        notApi: true,
    });

export default requiredProductRequest;
